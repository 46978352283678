import BtnBlancLeft from "Components/Boutons/BtnBlancLeft/btn-blanc-left";
import Filters from "Components/Filters/Filters";
import MassActions from "Components/Offres/GestionDepotOffre/MassActions/MassActions";
import { updateMenu } from "Redux/Actions/menuActions";
import { useFetchGet } from "Services/api";
import { formatDateDatabase, getTransmitterId } from "Services/functions";
import { fr } from "date-fns/locale";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import { FaPen } from "react-icons/fa";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "../Evenements/EvenementsManagement/EvenementsManagement.scss";
import "./Management.scss";
import { ALL, ME, RECRUTEUR } from "./ManagementButtons";

registerLocale("fr", fr);
const Management = (props) => {
  const { t } = useTranslation("common");

  // *****************************************************************
  // ****************** UseStates / UseRef *********************
  // *****************************************************************
  const diffusersData = useFetchGet("/diffusers", props.auth.token);
  const companiesData = useFetchGet("/companies/only_name", props.auth.token);

  const [filtreStatus, setFiltreStatus] = useState(null);
  const [filtreMotsCles, setFiltreMotsCles] = useState("");
  const [filtreDate, setFiltreDate] = useState(null);
  const [filtreCompany, setFiltreCompany] = useState(null);
  const [filtreDiffuseur, setFiltreDiffuseur] = useState(null);
  const [filtreOnlyCompanies, setFiltreOnlyCompanies] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [filterCompanyList, setFilterCompanyList] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [filterDiffuseurList, setFilterDiffuseurList] = useState([]);
  const [massActions, setMassActions] = useState(false);
  const [isKeywordFiltering, setIsKeywordFiltering] = useState(false);

  // *****************************************************************
  // ****************** UseEffect *********************
  // *****************************************************************

  useEffect(() => {
    diffusersData.loaded && setFilterDiffuseurList(diffusersData.data);

    companiesData.loaded &&
      companiesData.data &&
      setFilterCompanyList(companiesData.data["hydra:member"]);
  }, [diffusersData.loaded, companiesData.loaded]);

  // Hook qui se déclenche au changement de statut d'un des filtres
  useEffect(() => {
    if (!filtreDate || (filtreDate[0] && filtreDate[1])) {
      handleFilter();
    }
  }, [
    filtreStatus,
    filtreDate,
    filtreCompany,
    filtreDiffuseur,
    isKeywordFiltering,
    filtreOnlyCompanies,
  ]);

  /**
   * En fonction des paramètres de recherche, et du type de données à afficher,
   * on trie les données différemment.
   * @param {URL} url
   */
  function handleSortOrder(url) {
    switch (props.actualPage) {
      // Dans le cas d'une offre, si pas de filtre de date, on trie par date de modification
      // sinon par date de création
      case "offre":
        if (
          !url.searchParams.get("createdAt[after]") &&
          !url.searchParams.get("createdAt[before]")
        ) {
          url.searchParams.set("order[modifiedAt]", "desc");
        } else url.searchParams.set("order[createdAt]", "desc");
        break;
      // Dans le cas d'un événement, on trie par date de début
      // en prenant en compte la date actuelle pour la date de fin
      case "événement":
        url.searchParams.set("order[startingAt]", "asc");
        url.searchParams.set("endingAt[after]", formatDateDatabase(new Date()));
        break;
      // Dans le cas d'une actualité, on trie par date de modification
      case "actu":
        url.searchParams.set("order[modifiedAt]", "desc");
        break;
      default:
        break;
    }
  }

  /**
   * Gère l'ajout des paramètres de recherche pour les statuts
   * - Si un statut est sélectionné, on le rajoute
   * - Sinon on ajoute tous les statuts
   * - Sauf si le seul transmetteur est un recruteur, on enlève le statut publié (pour empêcher l'affichage)
   * @param {URL} url
   */
  function addStateParams(url) {
    // Si un statut est sélectionné, on le rajoute
    if (filtreStatus) {
      url.searchParams.set("state", filtreStatus.id);
    }
    // Sinon on ajoute tous les statuts
    else {
      props.states?.forEach((state) => {
        // Dans le cas d'un transmetteur recruteur, on enlève le statut publié
        if (
          state?.statusLabel === "Publié" &&
          props.currentTransmitter[0] === RECRUTEUR &&
          props.currentTransmitter?.length === 1
        ) {
          return;
        }
        // Dans le cas ou l'utilisateur n'est pas le transmitter, on enlève le statut "Brouillon"
        else if (
          state?.statusLabel === "Brouillon" &&
          props.currentTransmitter[0] !== ME
        ) {
          return;
        } else url.searchParams.append("state[]", state?.id);
      });
    }
  }

  /**
   * Ajoute les paramètres de recherche pour la provenance des données
   * en fonction de
   * - Utilisateur admin ou validateur d'offres
   * - Si l'utilisateur est sur ses propres données
   * - Si on a choisi 'TOUT' ou 'RECRUTEUR' comme transmetteur
   * @param {URL} url
   */
  function addCreatorSearchParams(url) {
    const isOfferPage = props.actualPage === "offre";
    const hasElevatedRights =
      props.auth.isAdmin || (props.auth.isValidator && isOfferPage);

    // Si l'user est admin OU validateur d'offres et qu'il n'est pas sur ses propres offres
    if (hasElevatedRights && props.currentTransmitter[0] !== ME) {
      // Si on a TOUT choisi ou le transmetteur RECRUTEUR ; on enleve le filtre transmitter pour afficher toutes les offres
      if (
        props.currentTransmitter[0] === ALL ||
        props.currentTransmitter[0] === RECRUTEUR
      ) {
        url.searchParams.delete("transmitter[]");
      } else {
        // Sinon, on ajoute le filtre transmitter avec ce qui est selectionné
        getTransmitterId(props.currentTransmitter).forEach((transmitterId) => {
          url.searchParams.append("transmitter[]", transmitterId);
        });
      }
    }
    // Si l'user n'a pas les droits élevés on indique que les données doivent être les siennes
    else {
      // On ajoute le filtre createdBy[] ou createdByUser[] en fonction de la page actuelle
      const property =
        props.actualPage === "offre" ? "createdByUser[]" : "createdBy[]";
      url.searchParams.append(property, props.auth.userConnected.id);
    }

    // Si on filtre uniquement les entreprises
    if (filtreOnlyCompanies) {
      // Suppression des transmetteurs actuels
      url.searchParams.delete("transmitter[]");
      // Ajout de 'Recruteur' comme unique transmetteur
      url.searchParams.append(
        "transmitter[]",
        props.secondaryTables.transmittersData.find(
          (transmitter) => transmitter.name === "Recruteur"
        )?.id
      );
    }
  }

  /**
   * Ajoute les paramètres de recherche dans l'url
   * - Nombre d'éléments par page
   * - Provenance / créateur des données
   * - Mots clés
   * - Statut(s)
   * - Date(s)
   * - Entreprise
   * - Diffuseur
   * @param {URL} url - URL à laquelle ajouter les paramètres
   * @returns {URL} - URL avec les paramètres de recherche
   */
  function addSearchParams(url) {
    // --- Ajout du nombre d'éléments par page --- (toujours à 20)
    url.searchParams.set(
      "itemsPerPage",
      Number(props.itemsPerPage || 20).toString()
    );

    // --- Ajout des filtres de provenance des données ---
    addCreatorSearchParams(url);

    // --- Ajout des mots clés ---
    if (filtreMotsCles) url.searchParams.set("keyword", filtreMotsCles);

    // --- Ajout des statuts ---
    addStateParams(url);

    // --- Ajout des filtres de date ---

    if (filtreDate && filtreDate[0])
      url.searchParams.set(
        props.actualPage === "événement"
          ? "startingAt[after]"
          : "createdAt[after]",
        formatDateDatabase(filtreDate[0])
      );
    if (filtreDate && filtreDate[1])
      url.searchParams.set(
        props.actualPage === "événement"
          ? "endingAt[before]"
          : "createdAt[before]",
        formatDateDatabase(filtreDate[1])
      );
    // --- Ajout des filtres d'entreprise et de diffuseur ---
    if (filtreCompany) url.searchParams.set("companyName", filtreCompany.name);
    if (filtreDiffuseur) url.searchParams.set("diffuser", filtreDiffuseur.id);

    // Ajout de l'université actuelle
    url.searchParams.set("university[]", props.auth.universityConnected.id);

    // --- Gestion de l'ordre de tri ---
    handleSortOrder(url);
    return url;
  }

  // Fonction globale de filtre
  const handleFilter = () => {
    let url = null;
    if (!props?.states?.length > 0) return;

    // Construit l'url en fonction de la page actuelle
    switch (props.actualPage) {
      case "offre":
        url = new URL(`${process.env.REACT_APP_BASE_URL_API}/offers`);
        break;
      case "événement":
        url = new URL(`${process.env.REACT_APP_BASE_URL_API}/events`);
        break;
      default:
        url = new URL(`${process.env.REACT_APP_BASE_URL_API}/news`);
        break;
    }
    // Ajout du suffixe /recruiter si le transmetteur est un recruteur
    if (
      props.currentTransmitter[0] === RECRUTEUR &&
      props.currentTransmitter?.length === 1
    ) {
      url = new URL(`${url.toString()}/recruiter`);
    }

    // Ajout des paramètres de recherche
    url = addSearchParams(url);
    props.setUrl(url);
  };

  // Si on change de transmetteur, on remet le filtre à false pour ne pas persister le filtre sur les autres requêtes
  useEffect(() => {
    setFiltreOnlyCompanies(false);
  }, [props.offers.currentTransmitter]);

  useEffect(() => {
    if (props.states?.length > 0 && !props.url) handleFilter();
  }, [props.states, props.url]);

  return (
    <div className="management_new_event_offer_filtres">
      <div className="management_new_event_offer_filtres__top">
        <Filters
          filtreMotsCles={filtreMotsCles}
          setFiltreMotsCles={setFiltreMotsCles}
          filtreDate={filtreDate}
          setFiltreDate={setFiltreDate}
          filtreDropdown={filtreStatus}
          setFiltreDropdown={(value) => {
            if (props.actualPage === "offre") {
              if (value?.statusLabel === "En Attente") setMassActions(true);
              else {
                setMassActions(false);
                props.setItemsPerPage(20);
              }
            }

            setFiltreStatus(value);
          }}
          dataDropdown={(() => {
            const isRecruiter =
              (props.offers.currentTransmitter[0] === RECRUTEUR &&
                props.offers.currentTransmitter.length === 1) ||
              (props.currentTransmitter[0] === RECRUTEUR &&
                props.currentTransmitter.length === 1);

            let states = props.states;

            if (isRecruiter) {
              // Remove "Publié" status if the transmitter is a recruiter
              states = props.states?.filter(
                (state) => state?.statusLabel !== "Publié"
              );
            }
            if (!props.currentTransmitter.includes(ME)) {
              // Remove "Brouillon" status if not viewing own offers
              states = props.states?.filter(
                (state) => state?.statusLabel !== "Brouillon"
              );
            }
            return states;
          })()}
          dropdownOption="statusLabel"
          setIsKeywordFiltering={setIsKeywordFiltering}
          filterFunction={handleFilter}
          noReset
        ></Filters>
        {/* Filtre par entreprise si le isActive du bouton entreprise est true */}
        {props.auth.isConnected &&
          (props.auth.isAdmin ||
            (props.actualPage === "offre" && props.auth?.isValidator)) &&
          props.btnEntreprise.isActive && (
            <Dropdown
              value={filtreCompany}
              className="membership_input_search"
              placeholder={t("filters.companyFilter")}
              showClear
              filter
              filterBy="name"
              options={filterCompanyList?.sort((a, b) =>
                a.name.localeCompare(b.name)
              )}
              optionLabel="name"
              onChange={(e) => {
                setFiltreCompany(e.target.value);
              }}
            />
          )}
        {/* Filtre par entreprise si les transmetteurs sont ceux de l'univ */}

        {props.currentTransmitter.length > 1 &&
          props.actualPage === "offre" &&
          props.auth.isConnected &&
          (props.auth.isAdmin ||
            (props.actualPage === "offre" && props.auth.isValidator)) && (
            <>
              <Checkbox
                inputId="onlyCompanies"
                name="onlyCompanies"
                value={true}
                checked={filtreOnlyCompanies}
                onChange={(e) => {
                  setFiltreOnlyCompanies(e.checked);
                }}
                style={{
                  marginLeft: "0.5rem",
                }}
              />

              <label
                htmlFor="onlyCompanies"
                style={{
                  marginLeft: "0.5rem",
                }}
              >
                {t("filters.companyFilterCheckbox")}
              </label>
            </>
          )}
        {/* Filtre par diffuseur si le isActive du bouton diffuseur est true */}
        {props.auth.isConnected &&
          (props.auth.isAdmin ||
            (props.actualPage === "offre" && props.auth?.isValidator)) &&
          props.btnDiffuseur?.isActive && (
            <Dropdown
              value={filtreDiffuseur}
              className="membership_input_search"
              placeholder={t("filters.diffuserFilter")}
              showClear
              options={filterDiffuseurList}
              onChange={(e) => {
                setFiltreDiffuseur(e.target.value);
              }}
            />
          )}
        {/* refresh filters */}
        {(filtreMotsCles ||
          filtreStatus ||
          filtreCompany ||
          filtreDiffuseur ||
          filtreOnlyCompanies ||
          (filtreDate && filtreDate[1])) && (
          <li className="management_new_event_offer_filtres__top__refresh">
            <i
              className="pi pi-refresh"
              style={{ fontSize: "1.5rem", fontWeight: "600" }}
              onClick={() => {
                setFiltreStatus(null);
                setIsKeywordFiltering(false);
                setFiltreDiffuseur(null);
                setFiltreOnlyCompanies(false);
                setFiltreCompany(null);
                setFiltreDate(null);
                setFiltreMotsCles("");
                props.setItemsPerPage(20);
                setMassActions(false);
              }}
            />
          </li>
        )}
        {props.actualPage &&
          props.actualPage === "événement" &&
          (props.auth.isAlumni || props.auth.isPersonnel) && (
            <div className="management_new_event_offer_filtres__top__createBtn">
              <Link to={{ pathname: `/evenements` }}>
                <BtnBlancLeft
                  btnTexte={t("eventApresConnexion.submenuProposerAlumni")}
                  btnAction={() => {
                    props.handleMenu({
                      activeItemEvenement: props.items.itemsEvenement.findIndex(
                        (v) => v.label == "Proposer un événement"
                      ),
                    });
                  }}
                ></BtnBlancLeft>
              </Link>
            </div>
          )}
        {props.actualPage &&
          props.actualPage === "événement" &&
          props.auth.isAdmin && (
            <div className="management_new_event_offer_filtres__top__createBtn">
              <Link to={{ pathname: `/evenements` }}>
                <BtnBlancLeft
                  btnTexte={t("eventApresConnexion.submenuCreateAdmin")}
                  btnAction={() => {
                    props.handleMenu({
                      activeItemEvenementAdmin:
                        props.items.itemsEvenementAdmin.findIndex(
                          (v) => v.label == "Créer un événement"
                        ),
                    });
                  }}
                ></BtnBlancLeft>
              </Link>
            </div>
          )}
        {props.actualPage &&
          props.actualPage === "actu" &&
          (props.auth.isAlumni || props.auth.isPersonnel) && (
            <div className="management_new_event_offer_filtres__top__createBtn">
              <BtnBlancLeft
                btnTexte={t("actusApresConnexion.submenuCreateAlumni")}
                btnAction={() =>
                  props.handleMenu({
                    activeItemActu: props.items.itemsActu.findIndex(
                      (v) => v.label == "Proposer une actu"
                    ),
                  })
                }
              >
                <FaPen />
              </BtnBlancLeft>
            </div>
          )}
        {props.actualPage &&
          props.actualPage === "actu" &&
          props.auth.isAdmin && (
            <div className="management_new_event_offer_filtres__top__createBtn">
              <BtnBlancLeft
                btnTexte={t("actusApresConnexion.submenuCreateAdmin")}
                btnAction={() =>
                  props.handleMenu({
                    activeItemActuAdmin: props.items.itemsActuAdmin.findIndex(
                      (v) => v.label == "Créer une actu/conseil"
                    ),
                  })
                }
              >
                <FaPen />
              </BtnBlancLeft>
            </div>
          )}
      </div>
      {massActions && (props.auth.isAdmin || props.auth?.isValidator) && (
        <MassActions
          itemsPerPage={props.itemsPerPage}
          setItemsPerPage={props.setItemsPerPage}
          url={props.url}
          setUrl={props.setUrl}
          refresh={props.refresh}
        ></MassActions>
      )}
    </div>
  );
};

Management.propTypes = {
  auth: PropTypes.object,
  items: PropTypes.object,
  offers: PropTypes.object,
  secondaryTables: PropTypes.object,
  refresh: PropTypes.func,

  handleMenu: PropTypes.func,
  updateFunction: PropTypes.func,

  states: PropTypes.array,

  btnEntreprise: PropTypes.object,
  btnDiffuseur: PropTypes.object,

  currentTransmitter: PropTypes.array,

  actualPage: PropTypes.string,
  itemsPerPage: PropTypes.number,
  setItemsPerPage: PropTypes.func,

  url: PropTypes.objectOf(URL),
  setUrl: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  items: state.items,
  offers: state.offers,
  secondaryTables: state.secondaryTables,
});

const mapDispatchToProps = (dispatch) => ({
  handleMenu: (value) => {
    dispatch(updateMenu(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Management);
