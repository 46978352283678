import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import BtnRond from "Components/Boutons/BtnRond/btn-rond";
import Modal from "Components/Modal/Modal";
import { updateOffers } from "Redux/Actions/offersActions";
import axios from "axios";
import { InputTextarea } from "primereact/inputtextarea";
import { Menu } from "primereact/menu";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { FaEllipsisH } from "react-icons/fa";
import { connect, useSelector } from "react-redux";
import DepotOffre from "../../DepotOffre/DepotOffre";
import "./GestionDepotOffreActions.scss";

const GestionDepotOffreActions = (props) => {
  const menu = useRef(null);
  const [openRefusInterface, setOpenRefusInterface] = useState(false);
  const [openModifyInterface, setOpenModifyInterface] = useState(false);
  const [refus, setRefus] = useState("");
  const [errorRefus, setErrorRefus] = useState(false);
  const [openMotifRefusInterface, setOpenMotifRefusInterface] = useState(false);

  const statesState = useSelector((state) => state.states);

  const itemsPublie = [
    {
      label: "Modifier",
      command: () => {
        setOpenModifyInterface(!openModifyInterface);
      },
    },
    { separator: true },
    {
      label: "Supprimer",
      command: () => {
        deleteOffer();
      },
    },
  ];
  const itemsBrouillon = [
    {
      label: "Supprimer",
      command: () => {
        deleteOffer();
      },
    },
  ];

  const itemsRefuse = [
    {
      label: "Renouveler la demande",
      command: () => {
        setOpenMotifRefusInterface(!openMotifRefusInterface);
      },
    },
  ];
  const itemsRefuseAdmin = [
    {
      label: "Supprimer",
      command: () => {
        deleteOffer();
      },
    },
  ];

  const itemsAttente = [
    {
      label: "Modifier",
      command: () => {
        setOpenModifyInterface(!openModifyInterface);
      },
    },
    { separator: true },
    {
      label: "Annuler",
      command: () => {
        deleteOffer();
      },
    },
  ];
  const itemsAttenteAdmin = [
    {
      label: "Accepter",
      command: () => {
        modifyStatus("validate");
      },
    },
    { separator: true },
    {
      label: "Refuser",
      command: () => {
        setOpenRefusInterface(!openRefusInterface);
      },
    },
    { separator: true },
    {
      label: "Archiver",
      command: () => {
        modifyStatus("archive");
      },
    },
    { separator: true },
    {
      label: "Modifier",
      command: () => {
        setOpenModifyInterface(!openModifyInterface);
      },
    },
  ];

  // Si on est sur une offre recruteur, on reprend itemsAttenteAdmin sans le bouton 'Modifier'

  const itemsRecruteurAttenteAdmin = itemsAttenteAdmin.filter(
    (item) => item.label !== "Modifier"
  );

  const itemsArchive = [
    {
      label: "Accepter",
      command: () => {
        modifyStatus("validate");
      },
    },
    { separator: true },
    {
      label: "Refuser",
      command: () => {
        setOpenRefusInterface(!openRefusInterface);
      },
    },
  ];

  const modifyStatus = (path) => {
    if (!props.offre?.transmitter) return;

    let url = `${process.env.REACT_APP_BASE_URL_API}/offers/${props.id}/${path}`;

    if (props.isRecruteur) {
      url += "/recruiter";
      // Si on est sur une offre recruteur, on remplace publish par validate
      if (path === "publish") url = url.replace("publish", "validate");
    }

    let data = {};
    if (path === "refuse") data = { feedback: refus };
    axios
      .post(url, data, {
        headers: props.auth.token
          ? {
              accept: "application/json",
              Authorization: `Bearer ${props.auth.token}`,
            }
          : {
              accept: "application/json",
            },
      })
      .then((res) => {
        let tempVar = props.offers?.dataFromPagination;
        let toastMessage = "";
        if (tempVar?.data) {
          if (props.isRecruteur && path === "validate") {
            tempVar.data = tempVar.data?.filter(
              (offer) => offer.id !== res.data.id
            );
            toastMessage =
              "L'offre a bien été acceptée, et sera désormais visible dans l'onglet 'Offres Universités' après un léger délai de traitement";
          } else {
            tempVar.data = tempVar.data?.map((offer) => {
              if (offer.id === res.data.id) {
                return res.data;
              } else {
                return offer;
              }
            });
          }
        }
        if (!toastMessage) {
          toastMessage = `L'offre a bien été ${
            path === "refuse"
              ? "refusée"
              : path === "archive"
              ? "archivée"
              : "acceptée"
          }`;
        }
        if (toastMessage) {
          props.auth.toast?.current?.show({
            severity: "success",
            summary: "Succès",
            detail: toastMessage,
            life: 6000,
          });
        }
        if (props.refresh) props.refresh();
        if (props.setOffre && res.data.state) {
          props.setOffre(res.data);
        }
        props.handleUpdateOffers({
          dataFromPagination: tempVar,
        });
      })
      .catch((error) =>
        props.auth.toast?.current?.show({
          severity: "error",
          summary: "Erreur",
          detail: error?.response?.data?.detail || "Une erreur est survenue",
          life: 5000,
        })
      );
  };

  const deleteOffer = () => {
    if (!props.offre?.transmitter) return;
    let useToken = true;

    let url = `${process.env.REACT_APP_BASE_URL_API}/offers/${props.id}`;

    if (props.isRecruteur) {
      url = `${process.env.REACT_APP_COMPANY_URL_API}/offers/${props.id}`;
      useToken = false;
    }

    axios
      .delete(url, {
        headers:
          props.auth.token && useToken
            ? {
                accept: "application/json",
                Authorization: `Bearer ${props.auth.token}`,
              }
            : {
                accept: "application/json",
              },
      })
      .then(() => {
        let tempVar = props.offers.dataFromPagination;
        if (tempVar?.data) {
          tempVar.data = tempVar.data.filter((offer) => offer.id !== props.id);
          props.handleUpdateOffers({
            dataFromPagination: tempVar,
          });
        }
        props.auth.toast?.current?.show({
          severity: "success",
          summary: "Succès",
          detail: "L'offre a bien été supprimée",
          life: 6000,
        });
        if (props.refresh) props.refresh();
        if (props.setOffre)
          props.setOffre({
            ...props.offre,
            state:
              statesState.statesData.find(
                (state) => state.statusLabel === "Supprimé"
              ) || props.offre.state,
          });
      })
      .catch((error) => {
        console.log("error", error);
        props.auth.toast?.current?.show({
          severity: "error",
          summary: "Erreur",
          detail: error?.response?.data?.detail || "Une erreur est survenue",
          life: 5000,
        });
      });
  };

  const Statut = () => {
    switch (props.statut.statusLabel) {
      case "Archivé":
        return itemsArchive;
      case "En Attente":
        switch (props.auth.isAdmin || props.auth.isValidator) {
          case true:
            if (props.isRecruteur) return itemsRecruteurAttenteAdmin;
            return itemsAttenteAdmin;
          default:
            return itemsAttente;
        }
      case "Publié":
        return itemsPublie;
      case "Refusé":
        switch (props.auth.isAdmin || props.auth.isValidator) {
          case true:
            return itemsRefuseAdmin;
          default:
            return itemsRefuse;
        }
      case "Brouillon":
        return itemsBrouillon;
      default:
        break;
    }
  };
  return (
    <div className="groupe_menu_offers">
      <div className={`groupe_menu ${props.secondClass}`}>
        <Menu
          appendTo={document.getElementById("app")}
          model={Statut()}
          popup
          ref={menu}
          style={{ textAlign: "center" }}
        />
        <BtnRond btnAction={(event) => menu.current.toggle(event)}>
          <FaEllipsisH size={"1.5rem"} />
        </BtnRond>
      </div>
      {openRefusInterface && (
        <Modal
          visible={openRefusInterface}
          setVisible={setOpenRefusInterface}
          header={`Justifiez votre refus de l'offre`}
        >
          <div className="modal_motif_refus">
            <InputTextarea
              className="modal_refus"
              rows={6}
              placeholder="Justifiez votre refus"
              value={refus}
              onChange={(e) => setRefus(e.target.value)}
            />
            {errorRefus && (
              <span className="error_refus_length">
                Le motif de refus doit faire au moins 10 caractères
              </span>
            )}
            <BtnBleu
              btnTexte="Envoyer"
              btnAction={() => {
                if (refus.length > 9) {
                  setErrorRefus(false);
                  setRefus("");
                  setOpenRefusInterface(false);
                  modifyStatus("refuse");
                } else setErrorRefus(true);
              }}
            />
          </div>
        </Modal>
      )}
      {openMotifRefusInterface && (
        <Modal
          visible={openMotifRefusInterface}
          setVisible={setOpenMotifRefusInterface}
          header={`Motif du refus`}
        >
          <div className="motif_refus_interface">
            <div className="motif_refus_interface_message">
              <span className="motif_refus_interface_message_title">
                Motif du refus :
              </span>
              <span className="motif_refus_interface_message_text">
                {props.feedback
                  ? props.feedback
                  : " Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim maiores vero totam exercitationem at asperiores quibusdam eligendi labore fuga, porro tempora dolor nihil repellat error aut distinctio et earum molestiae accusamus rem."}
              </span>
            </div>
            <div className="motif_refus_interface_bouton">
              <BtnBleu
                btnTexte="Renouveler la demande"
                btnAction={() => {
                  setOpenModifyInterface(true);
                  setOpenMotifRefusInterface(false);
                }}
              ></BtnBleu>
            </div>
          </div>
        </Modal>
      )}
      {openModifyInterface && (
        <Modal
          visible={openModifyInterface}
          setVisible={setOpenModifyInterface}
          header={`Modifiez votre offre`}
        >
          <DepotOffre
            activeIndex={props.items.itemsOffre.findIndex(
              (v) => v.label == "Déposer une offre"
            )}
            paramItems={props.items.itemsOffre}
            offerId={props.offre?.id}
            setVisible={setOpenModifyInterface}
            recruteur={props.isRecruteur}
            setOffre={props.setOffre}
          ></DepotOffre>
        </Modal>
      )}
    </div>
  );
};

GestionDepotOffreActions.propTypes = {
  statut: PropTypes.object,
  id: PropTypes.number,
  offre: PropTypes.object,
  feedback: PropTypes.string,
  secondClass: PropTypes.string,
  isRecruteur: PropTypes.bool,
  setOffre: PropTypes.func,

  auth: PropTypes.object,
  offers: PropTypes.object,
  items: PropTypes.object,
  refresh: PropTypes.func,

  handleUpdateOffers: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  items: state.items,
  offers: state.offers,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateOffers: (value) => {
    dispatch(updateOffers(value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GestionDepotOffreActions);
