import React, { useState, useEffect, useCallback } from "react";
import "./GroupeGestion.scss";
import PropTypes from "prop-types";
import GroupeCardGestion from "../GroupeCard/GroupeCardGestion/GroupeCardGestion";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { HiSearch } from "react-icons/hi";
import BtnBlancLeft from "Components/Boutons/BtnBlancLeft/btn-blanc-left";
import { FaCog, FaPen } from "react-icons/fa";
import GroupeDemande from "../GroupeDemande/GroupeDemande";
import { updateGroups } from "Redux/Actions/groupsActions";
import { Link } from "react-router-dom";
import Loader from "Components/Loader/loader";
import { useRef } from "react";
import { updatePagination } from "Redux/Actions/paginationActions";
import { fetchGet, useFetchGetPagination } from "Services/api";
import { Toast } from "primereact/toast";
import { useMemo } from "react";
import TempPagination from "Components/Pagination/TempPagination";
import { isNotEmptyArray } from "Services/functions";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { debounce } from "../../../Services/debounce";

const GroupeGestion = (props) => {
  // Filtres de recherche
  const [filtreStatut, setFiltreStatut] = useState(null);
  const [filtreMotsCles, setFiltreMotsCles] = useState("");

  const { t } = useTranslation("common");

  // URL des groupes invités
  const [invitedGroupsUrl, setInvitedGroupsUrl] = useState("");
  // Données des groupes invités
  const [invitedGroups, setInvitedGroups] = useState([]);

  // Données des groupes gérés
  const [managedGroups, setManagedGroups] = useState([]);

  const [updateManagedGroups, setUpdateManagedGroups] = useState(false);
  const managedGroupsQuery = useFetchGetPagination(
    props.groups.filteringURL,
    props.auth.token,
    props.handleUpdateGroups
  );

  const invitedGroupsQuery = useFetchGetPagination(
    invitedGroupsUrl,
    props.auth.token
  );

  const [checkInvite, setCheckInvite] = useState(true);

  // Liste des statuts (triés par ordre alphabétique plus tard)
  const [statuses, setStatuses] = useState([]);

  const groupsContainerRef = useRef();

  // GESTION TOASTS SUR ACTIONS GESTION GROUPES
  const [managementHandler, setManagementHandler] = useState({
    visible: false,
    type: undefined,
    header: undefined,
    detail: undefined,
  });
  const managementRef = useRef();

  /**
   *
   * @param {URL} url
   */
  const groupAdminFilters = (url) => {
    const adminRole = props.groups.roles?.find(
      (role) => role.roleName === "ROLE_GROUP_ADMIN"
    );
    if (adminRole) url.searchParams.append("memberRole[]", adminRole.id);
    return url;
  };

  const invitedFilters = (url) => {
    const invitedStatus =
      props.states.groupMemberStatesData?.find(
        (status) => status.label == "INVITE"
      ) || null;
    if (invitedStatus)
      url.searchParams.append("memberState[]", invitedStatus.id);
    return url;
  };

  const filterByStatus = (url) => {
    // Reset des filtres de statut
    url.searchParams.delete("state[]");
    if (!filtreStatut) {
      // Si aucun statut, on filtre par tous les statuts sauf ceux qui ne sont pas des statuts de groupe (Supprimé, Brouillon, etc.)
      statuses.forEach((status) => {
        if (
          status.id &&
          status.statusLabel != "Brouillon" &&
          status.statusLabel != "Cloturé" &&
          status.statusLabel != "Publié" &&
          status.statusLabel != "Archivé" &&
          status.statusLabel != "Supprimé" &&
          status.statusLabel != "Pourvue"
        )
          url.searchParams.append("state[]", status.id);
      });
    } else {
      url.searchParams.append(
        "state[]",
        statuses.find(
          (status) => status.statusLabel === filtreStatut.statusLabel
        ).id
      );
    }
    return url;
  };

  const filterByKeyWords = (url) => {
    url.searchParams.append("label", filtreMotsCles);
    return url;
  };

  const updateUrl = (invited = false) => {
    let url = process.env.REACT_APP_BASE_URL_API;
    url += props.auth.isAdmin
      ? "/groups"
      : "/groups/member/" + props.auth.userConnected.id;
    let filteringURL = new URL(url);
    filteringURL.searchParams.append("itemsPerPage", 7);
    // Si l'utilisateur n'est pas admin du portail, on filtre les groupes pour ne récupérer que ceux dont il est admin
    if (!props.auth.isAdmin && !invited)
      filteringURL = groupAdminFilters(filteringURL);
    if (!props.auth.isAdmin && invited)
      filteringURL = invitedFilters(filteringURL);

    // Filtrage par mots clés / statut
    if (filtreMotsCles) filteringURL = filterByKeyWords(filteringURL);
    filteringURL = filterByStatus(filteringURL);
    return filteringURL;
  };

  /**
   * Mise en debounce du changement d'url des différentes recherches de groupes
   * pour éviter les appels trop fréquents niveau API
   * @type {(function(...[*]): void)|*}
   */
  const updateGroupsURLs = useCallback(
    debounce((managedGroupsURL, invitedGroupsURL) => {
      if (!props.auth.isAdmin) {
        setInvitedGroupsUrl(invitedGroupsURL);
      }
      props.handleUpdateGroups({
        filteringURL: managedGroupsURL,
      });
    }, 500),
    []
  );

  const updateGroups = () => {
    if (isNotEmptyArray(props.groups.roles)) {
      let managedGroupsURL = updateUrl();
      let invitedGroupsURL = updateUrl(true);
      updateGroupsURLs(managedGroupsURL, invitedGroupsURL);
    }
  };

  const retrieveGroupMemberId = async (group) => {
    const invitedState = props.states.groupMemberStatesData.find(
      (state) => state.label === "INVITE"
    );
    let groupMember = await fetchGet("/group_members", props.auth.token, {
      groupSource: group.id,
      user: props.auth.userConnected.id,
      groupMemberStatus: invitedState.id,
    });
    group.inviteId = groupMember[0]?.id;
    return group;
  };

  useEffect(async () => {
    if (invitedGroupsQuery.data && checkInvite) {
      // We add the invited groups to the list of groups while adding a field to indicate that they are invited
      // to do so we fetch the group member id for each group

      let invitedGroupsData = await Promise.all(
        invitedGroupsQuery.data.map(async (group) =>
          retrieveGroupMemberId(group)
        )
      );
      setCheckInvite(false);
      setInvitedGroups(invitedGroupsData);
    }
  }, [invitedGroupsQuery]);

  useEffect(() => {
    if (managedGroupsQuery.loaded && managedGroupsQuery.totalItems)
      setManagedGroups(managedGroupsQuery?.data);
  }, [managedGroupsQuery]);

  //       - Groupes auxquels on est invité
  //       - Réaction aux modifs
  //       - Pagination pour demandes ?

  // Mise à jour de l'URL de filtrage des groupes en fonction des filtres
  useEffect(
    () => updateGroups(),
    [filtreStatut, filtreMotsCles, props.auth.isAdmin, props.groups.roles]
  );

  // Mise à jour de l'URL de filtrage des groupes après action de l'utilisateur
  useMemo(() => {
    if (managementHandler.visible && !updateManagedGroups) updateGroups();
    else if (updateManagedGroups) {
      updateGroups();
      setUpdateManagedGroups(false);
    }
  }, [managementHandler.visible, updateManagedGroups]);

  useMemo(() => {
    /// Récupérer les catégories, et les filtrer par ordre alphabétique
    if (props.states.statesData && props.states.statesData.length > 0) {
      setStatuses([]);
      let tempArray = [];
      props.states.statesData.forEach((status) => {
        if (
          status.statusLabel != "Brouillon" &&
          status.statusLabel != "Cloturé" &&
          status.statusLabel != "Publié" &&
          status.statusLabel != "Archivé" &&
          status.statusLabel != "Supprimé" &&
          status.statusLabel != "Pourvue"
        )
          tempArray.push(status);
      });
      tempArray.push({ statusLabel: "Invité" });
      tempArray = tempArray.sort((a, b) =>
        a.statusLabel.localeCompare(b.statusLabel)
      );
      setStatuses(tempArray);
    }
  }, [props.states.statesData]);

  // Affichage ou non des toasts sur la partie gestion de groupe
  useEffect(() => {
    if (managementHandler.visible) {
      managementRef.current.show({
        severity: managementHandler.type,
        summary: managementHandler.header,
        detail: managementHandler.detail,
        life: 5000,
      });
      setManagementHandler({
        visible: false,
        type: undefined,
        header: undefined,
        detail: undefined,
      });
    }
  }, [managementHandler.visible]);

  useEffect(() => {
    return () => {
      props.handleUpdateGroups({
        filteringURL: null,
      });
    };
  }, []);
  return (
    <div className="groupe_container">
      <Toast ref={managementRef} />
      <div className="groupe_container__title">
        <div>
          <h1 className="h1_trait_dessus">{t("groupManagement.header")}</h1>
        </div>

        <div className="groupe_container__title__filtres gestion">
          <span>
            <HiSearch className="searchBar_groupe_finder_icon" />
            <InputText
              placeholder={t("groupManagement.searchGroup")}
              value={filtreMotsCles}
              className="membership_input_text"
              onChange={(e) => {
                props.handleUpdateGroups({
                  motsClés: e.target.value.toLowerCase(),
                  /// Mettre la recherche à true, seulement si au moins l'un des filtres est activé
                  isSearchedGroups:
                    e.target.value !== "" || filtreStatut != null,
                  // filtreDate != null,
                });
                setFiltreMotsCles(e.target.value.toLowerCase());
              }}
            />
          </span>
          {props.states.statesData && props.states.statesData.length > 0 ? (
            <Dropdown
              value={filtreStatut}
              options={statuses}
              optionLabel="statusLabel"
              showClear
              onChange={(e) => {
                props.handleUpdateGroups({
                  status: e.target.value,
                  /// Mettre la recherche à true, seulement si au moins l'un des filtres est activé
                  isSearchedGroups:
                    e.target.value !== "" || filtreMotsCles != null,
                });
                setFiltreStatut(e.target.value);
              }}
              placeholder={t("groupManagement.statusFilter")}
            />
          ) : (
            <Loader />
          )}

          {props.auth.isAdmin ? (
            <BtnBlancLeft
              btnTexte={t("groupManagement.createHeader")}
              btnAction={() => props.setCreation(!props.creation)}
            >
              <FaPen />
            </BtnBlancLeft>
          ) : (
            <BtnBlancLeft
              btnTexte={t("groupManagement.proposeHeader")}
              btnAction={() => props.setCreation(!props.creation)}
            >
              <FaCog />
            </BtnBlancLeft>
          )}
        </div>
      </div>
      {/* <p> Sous-titres à définir</p> */}
      <div className="groupe_main_section" ref={groupsContainerRef}>
        <div className="groupe_container_gestion_left">
          {managedGroupsQuery.totalItems || invitedGroupsQuery.totalItems ? (
            [...(managedGroups || []), ...(invitedGroups || [])]?.map(
              (group) =>
                group && (
                  <>
                    <Link to={"/groupes"} key={group.id}>
                      <GroupeCardGestion
                        group={group}
                        setManagementHandler={setManagementHandler}
                        image={
                          group.imageUrl
                            ? "link"
                            : "img" + ((group.id % 6) + 1).toString()
                        }
                        url={group.imageUrl}
                        admin={props.auth.isAdmin}
                        key={group.id}
                        inviteId={group?.inviteId ? group.inviteId : NaN}
                      />
                    </Link>
                  </>
                )
            )
          ) : (
            <>
              {managedGroupsQuery.loaded == false &&
              invitedGroupsQuery.loaded == false ? (
                <Loader />
              ) : (
                <div>{t("groupManagement.searchEmpty")}</div>
              )}
            </>
          )}
        </div>
        <div className="groupe_container_gestion_right">
          <GroupeDemande
            managedGroups={managedGroups}
            setUpdateManagedGroups={setUpdateManagedGroups}
          />
        </div>
      </div>
      {/* Pour afficher la pagination : 
      - il faut que la liste des groupes de l'user soit chargée
      - que celle-ci ne soit pas vide  */}
      {managedGroupsQuery.loaded && managedGroupsQuery.totalItems ? (
        <TempPagination
          result={managedGroupsQuery}
          refItemParent={groupsContainerRef}
          rows={7}
        />
      ) : null}
    </div>
  );
};

GroupeGestion.propTypes = {
  title: PropTypes.string,
  chapeau: PropTypes.string,
  bouton: PropTypes.string,
  auth: PropTypes.object,
  states: PropTypes.object,

  items: PropTypes.object,
  activeIndex: PropTypes.number,
  setActiveIndex: PropTypes.func,
  groups: PropTypes.object,
  handleUpdateGroups: PropTypes.func,

  pagination: PropTypes.object,
  handleUpdatePagination: PropTypes.func,

  creation: PropTypes.bool,
  setCreation: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  items: state.items,
  groups: state.groups,
  pagination: state.pagination,
  states: state.states,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateGroups: (value) => {
    dispatch(updateGroups(value));
  },
  handleUpdatePagination: (value) => {
    dispatch(updatePagination(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupeGestion);
