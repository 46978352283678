import React, { useMemo, useState } from "react";
import "./GroupeCardFind.scss";
import PropTypes from "prop-types";
import img_groupe1 from "assets/img_groupe1.webp";
import img_groupe2 from "assets/img_groupe2.webp";
import img_groupe3 from "assets/img_groupe3.webp";
import img_groupe4 from "assets/img_groupe4.webp";
import img_groupe5 from "assets/img_groupe5.webp";
import img_groupe6 from "assets/img_groupe6.webp";
import BtnBlancLeft from "Components/Boutons/BtnBlancLeft/btn-blanc-left";
import { FaPlusCircle } from "react-icons/fa";
import { stripHtml, truncate } from "Services/functions";
import { fetchGet } from "Services/api";
import { connect } from "react-redux";
import { updateGroups } from "Redux/Actions/groupsActions";
import { updateMenu } from "Redux/Actions/menuActions";
import { useNavigate } from "react-router-dom";

const GroupeCardFind = (props) => {
  const handleClick = () => {
    props.setDetailsGroup({
      visible: true,
      id: props.id,
    });
  };

  const navigate = useNavigate();

  const [groupMembersCount, setGroupMembersCount] = useState(null);

  useMemo(async () => {
    let count = undefined;
    if (props.id)
      count = await fetchGet(
        `/groups/${props.id}/number_of_active_members_in_the_group`,
        props.auth?.token
      );
    count && typeof count === "number" && setGroupMembersCount(count);
  }, [props.id]);

  /**
   * Navigue vers la page de détails du groupe sélectionné
   * Se déclenche au clic sur la card
   *
   * Uniquement si l'utilisateur est admin (car il appartient à tous les groupes)
   */
  const navigateToGroupDetails = () => {
    navigate("/groupe-detail/" + props.id);
  };

  /**
   * Affiche les détails du groupe sélectionné
   * Se déclenche au clic sur la card
   *
   * Uniquement si l'utilisateur n'est pas admin
   */
  const displayGroupDetails = () => {
    props.setDetailsGroup({
      visible: true,
      id: props.id,
    });
  };

  return (
    <div
      className="groupe_card_find_container"
      onClick={() => {
        if (props.auth.isAdmin) navigateToGroupDetails();
        else displayGroupDetails();
      }}
    >
      <div className="groupe_card_find_container__logo" onClick={handleClick}>
        {props.image == "img1" && (
          <img src={img_groupe1} alt="Logo du groupe" title="Logo du groupe" />
        )}
        {props.image == "img2" && (
          <img src={img_groupe2} alt="Logo du groupe" title="Logo du groupe" />
        )}
        {props.image == "img3" && (
          <img src={img_groupe3} alt="Logo du groupe" title="Logo du groupe" />
        )}
        {props.image == "img4" && (
          <img src={img_groupe4} alt="Logo du groupe" title="Logo du groupe" />
        )}
        {props.image == "img5" && (
          <img src={img_groupe5} alt="Logo du groupe" title="Logo du groupe" />
        )}
        {props.image == "img6" && (
          <img src={img_groupe6} alt="Logo du groupe" title="Logo du groupe" />
        )}
        {props.image == "link" && (
          <img src={props.url} alt="Logo du groupe" title="Logo du groupe" />
        )}
      </div>
      <div className="groupe_card_find_container__info" onClick={handleClick}>
        {screen.width <= 830 ? (
          <>
            <h6 className="title">{truncate(props?.titre, 30)}</h6>
            <h6>{truncate(stripHtml(props?.description), 60)}</h6>
          </>
        ) : (
          <>
            <h6 className="title">{truncate(props?.titre, 30)}</h6>
            <h6>{truncate(stripHtml(props?.description), 60)}</h6>
          </>
        )}

        <div className="groupe_card_find_container__info__bottom">
          {props.category && (
            <h2>
              <i className="pi pi-tag"></i> {props.category.label}
            </h2>
          )}
          {props.private ? (
            <h2>
              <i className="pi pi-lock"></i> Groupe privé{" "}
            </h2>
          ) : (
            <h2>
              <i className="pi pi-lock-open"></i> Groupe public{" "}
            </h2>
          )}

          <h2>
            <i className="pi pi-users"></i>
            {` ${groupMembersCount || 0} `}
          </h2>
        </div>
      </div>
      {/* Si l'utilisateur est admin du portail, ne pas placer le bouton rejoindre */}
      {!props.isPortalAdmin && (
        <div className="groupe_card_find_container__bouton">
          <BtnBlancLeft
            btnTexte="Rejoindre"
            btnAction={(event) => {
              // Empêche l'ouverture de la fiche groupe
              event.stopPropagation();

              props.setJoinGroup({
                title: props.titre,
                state: true,
                id: props.id,
                private: props.private,
              });
            }}
          >
            <FaPlusCircle />
          </BtnBlancLeft>
        </div>
      )}
    </div>
  );
};

GroupeCardFind.propTypes = {
  // State redux
  auth: PropTypes.object.isRequired,
  items: PropTypes.object.isRequired,
  // Actions redux
  handleMenu: PropTypes.func.isRequired,
  handleGroups: PropTypes.func.isRequired,

  isPortalAdmin: PropTypes.bool.isRequired,
  category: PropTypes.object,
  id: PropTypes.number,
  titre: PropTypes.string,
  private: PropTypes.bool,
  group: PropTypes.object,
  image: PropTypes.string,
  description: PropTypes.string,
  setJoinGroup: PropTypes.func,
  setDetailsGroup: PropTypes.func,
  url: PropTypes.string,
};

const mapStateToProps = (state) => ({
  items: state.items,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  handleMenu: (value) => {
    dispatch(updateMenu(value));
  },
  handleGroups: (value) => {
    dispatch(updateGroups(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupeCardFind);
