import BtnBlanc from "Components/Boutons/BtnBlanc/btn-blanc";
import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import { RECRUTEUR } from "Components/Management/ManagementButtons";
import Modal from "Components/Modal/Modal";
import { updateOffers } from "Redux/Actions/offersActions";
import axios from "axios";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import "./MassActions.scss";

const MassActions = (props) => {
  const [massActionConfirmation, setMassActionConfirmation] = useState({
    visible: false,
    type: "",
    action: "",
  });
  // En attente du back pour le codage de cette fonction
  const massActionsFunction = (action) => {
    setMassActionConfirmation({ visible: false, type: "", action: "" });

    if (!props.offers?.currentTransmitter?.length) return;
    let url = `${process.env.REACT_APP_BASE_URL_API}/offers/${action}/multiple`;
    if (props.offers.currentTransmitter[0] === RECRUTEUR) {
      url += "/recruiter";
    }
    // Si action === validate ; on envoi la liste des id des offres à accepter
    if (action === "validate") {
      url += "?ids=";
      props.offers.dataFromPagination?.data?.forEach(
        (offer) => (url = url.concat(`${offer.id},`))
      );
    } else {
      // Sinon on envoi l'ensemble des paramètres de l'URL de la recherche, à la requête de masse
      // (pour que le serveur puisse retrouver exactement les mêmes offres)
      const searchParams = new URLSearchParams(props.url?.search ?? "");
      // On enlève les paramètres de pagination, tri
      searchParams.delete("page");
      searchParams.delete("itemsPerPage");
      searchParams.delete("order[modifiedAt]");
      searchParams.delete("order[createdAt]");

      // On les ajoute à la requête de masse
      url += `?${searchParams.toString()}`;
    }
    let data = {};
    if (action === "refuse")
      data = {
        feedback: "Votre offre a été refusée par un administrateur du portail",
      };
    axios
      .post(url, data, {
        headers: props.auth.token && {
          Authorization: `Bearer ${props.auth.token}`,
        },
      })
      .then(() => {
        let tempData = props.offers.dataFromPagination;
        tempData.data = [];
        props.handleUpdateOffers({ dataFromPagination: tempData });
        props.refresh();
        props.auth.toast.current.show({
          severity: "success",
          summary: "Succès",
          detail: `Les offres ont bien été ${
            action === "refuse"
              ? "refusées"
              : action === "archive"
              ? "archivées"
              : "acceptées"
          }`,
          life: 3000,
        });
      })
      .catch(() => {
        props.auth.toast.current.show({
          severity: "error",
          summary: "Erreur",
          detail: "Une erreur est survenue lors de l'action de masse",
          life: 3000,
        });
      });
  };
  return (
    <div className="mass_actions_buttons">
      <BtnBlanc
        btnAction={() => {
          // Si il n'y a pas d'offres, on affiche un message
          if (!props.offers?.dataFromPagination?.data?.length) {
            props.auth.toast.current.show({
              severity: "error",
              summary: "Erreur",
              detail: "Aucune offre à accepter",
              life: 3000,
            });
            return;
          }
          setMassActionConfirmation({
            visible: true,
            type: "Accepter",
            action: "validate",
          });
        }}
      >
        Accepter tout
      </BtnBlanc>
      <BtnBlanc
        btnAction={() => {
          // Si il n'y a pas d'offres, on affiche un message
          if (!props.offers?.dataFromPagination?.data?.length) {
            props.auth.toast.current.show({
              severity: "error",
              summary: "Erreur",
              detail: "Aucune offre à refuser",
              life: 3000,
            });
            return;
          }
          setMassActionConfirmation({
            visible: true,
            type: "Refuser",
            action: "refuse",
          });
        }}
      >
        Refuser tout
      </BtnBlanc>
      <BtnBlanc
        btnAction={() => {
          // Si il n'y a pas d'offres, on affiche un message
          if (!props.offers?.dataFromPagination?.data?.length) {
            props.auth.toast.current.show({
              severity: "error",
              summary: "Erreur",
              detail: "Aucune offre à archiver",
              life: 3000,
            });
            return;
          }
          setMassActionConfirmation({
            visible: true,
            type: "Archiver",
            action: "archive",
          });
        }}
      >
        Archiver tout
      </BtnBlanc>
      {massActionConfirmation.visible && (
        <Modal
          visible={massActionConfirmation.visible}
          setVisible={() =>
            setMassActionConfirmation({ visible: false, type: "", action: "" })
          }
          header={`${massActionConfirmation.type} tout`}
        >
          <div className="mass_actions_buttons_confirmation">
            <span>
              {/*? Si il n'y a pas d'offres, on affiche un message */}
              {props.offers.dataFromPagination.data.length === 0
                ? "Aucune offre n'est concernée."
                : `Êtes-vous sur de vouloir ${massActionConfirmation.type.toLowerCase()} ${
                    props.offers.dataFromPagination.data.length === 1
                      ? "l'offre visible"
                      : "les " +
                        (massActionConfirmation.type === "Accepter"
                          ? `${props.offers.dataFromPagination?.data?.length} offres visibles`
                          : `${props.offers.dataFromPagination?.totalItems} offres`)
                  } ?`}
            </span>
            <BtnBleu
              btnAction={() =>
                massActionsFunction(massActionConfirmation.action)
              }
            >
              Confirmer
            </BtnBleu>
          </div>
        </Modal>
      )}
    </div>
  );
};

MassActions.propTypes = {
  auth: PropTypes.object,
  offers: PropTypes.object,
  handleUpdateOffers: PropTypes.func,
  itemsPerPage: PropTypes.number,
  setItemsPerPage: PropTypes.func,
  url: PropTypes.string,
  setUrl: PropTypes.func,
  refresh: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  offers: state.offers,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateOffers: (value) => {
    dispatch(updateOffers(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MassActions);
