import React, { useState, useEffect, useMemo } from "react";
import "./GroupeContainer.scss";
import PropTypes from "prop-types";
import Header from "Components/Header/Header";
import NavBarTab from "Components/NavBar/NavBarTab/NavBarTab";
import GroupeMembership from "../GroupeMembership/GroupeMembership";
import GroupeDetails from "../GroupeDetails/GroupeDetails";
import BtnBlanc from "Components/Boutons/BtnBlanc/btn-blanc";
import { FaCog } from "react-icons/fa";
import { updateMenu } from "Redux/Actions/menuActions";
import { connect } from "react-redux";
import GroupeCardSuggestion from "../GroupeCard/GroupeCardSuggestion/GroupeCardSuggestion";
import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import { updateGroups } from "Redux/Actions/groupsActions";
import Loader from "Components/Loader/loader";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import { updateUsers } from "Redux/Actions/usersActions";
import { isNotEmptyArray, shuffleArray } from "Services/functions";
import ModalRejoindreGroupe from "Components/Modal/ModalRejoindreGroupe/ModalRejoindreGroupe";
import ModalQuitterGroupe from "Components/Modal/ModalQuitterGroupe/ModalQuitterGroupe";
import { useFetchGetPagination } from "Services/api";
import { useTranslation } from "react-i18next";

/**
 * Permet d'afficher la page mes groupes d'achanges
 * GroupeMembership permet d'avoir la barre de gauche contenant les groupes dont l'utilisateur est membre
 * En fonction du groupe sur lequel on a cliqué, la partie droite affiche le détail du groupe GroupeDetails
 * Des suggestions de groupes institutionnels sont affichées en bas via GroupeCardSuggestion
 */
const GroupeContainer = (props) => {
  const [toUpdate, setToUpdate] = useState(true);
  const { t } = useTranslation("common");

  const [selectGroup, setSelectGroup] = useState(null);

  // ------------------------------ TOASTS ------------------------------
  // Ref pour les toasts rejoindre / quitter un groupe
  const groupJoinRef = useRef();
  const groupLeaveRef = useRef();

  // Affichage dialog si l'utilisateur veut rejoindre un groupe
  const [joinGroup, setJoinGroup] = useState({
    title: "",
    id: null,
    visible: false,
    state: null,
    result: null,
  });

  // Affichage dialog si l'utilisateur veut quitter un groupe
  const [leaveGroup, setLeaveGroup] = useState({
    title: "",
    id: null,
    visible: false,
    state: null,
    result: null,
  });

  // Action de désactiver un groupe
  const [deactivateGroup, setDeactivateGroup] = useState({
    visible: false,
    type: null,
    detail: null,
    header: null,
  });
  const deactivateGroupRef = useRef();

  // Gestion des offres affichées avec pagination
  const groupsQuery = useFetchGetPagination(
    props.groups.filteringURL,
    props.auth.token,
    props.handleUpdateGroups
  );

  // ------------------------------ SUGGESTIONS DE GROUPES ------------------------------
  const [suggestedGroupsUrl, setSuggestedGroupsUrl] = useState(null);
  const suggestedGroupsQuery = useFetchGetPagination(
    suggestedGroupsUrl,
    props.auth.token,
    props.handleUpdateGroups
  );

  const shuffledSuggestedGroups = useMemo(() => {
    if (isNotEmptyArray(suggestedGroupsQuery?.data)) {
      return shuffleArray(suggestedGroupsQuery.data);
    }
    return [];
  }, [suggestedGroupsQuery.data]);

  const makeMyGroupsUrl = () => {
    let url = process.env.REACT_APP_BASE_URL_API;
    // Si on est admin, on récupère tous les groupes
    // Sinon, on récupère les groupes dont l'utilisateur est membre
    url += props.auth.isAdmin
      ? "/groups"
      : "/groups/member/" + props.auth.userConnected.id;
    let filteringURL = new URL(url);
    filteringURL.searchParams.append(
      "state[]",
      props.states.statesData.find((status) => status.statusLabel === "Actif")
        .id
    );
    if (!props.auth.isAdmin)
      filteringURL.searchParams.append(
        "memberState[]",
        props.states.groupMemberStatesData.find(
          (status) => status.label === "ACTIF"
        ).id
      );
    // filteringURL.searchParams.set("order[datePosted]", "desc");
    filteringURL.searchParams.append("itemsPerPage", 8);
    props.handleUpdateGroups({
      filteringURL,
    });
  };

  useMemo(() => {
    if (toUpdate && props.states.statesData) {
      makeMyGroupsUrl();
      setToUpdate(false);
    }
  }, [props.states.statesData, props.groups.filteringURL, toUpdate]);

  // Récupération des groupes suggérés (institutionnels et non membres)
  // Si non admin uniquement, sinon on ne récupère pas les groupes suggérés
  useMemo(async () => {
    if (!props.auth.isAdmin) {
      let url =
        process.env.REACT_APP_BASE_URL_API +
        "/groups/not_member/" +
        props.auth.userConnected.id;
      let filteringURL = new URL(url);
      filteringURL.searchParams.append("institutional", true);
      filteringURL.searchParams.append("page", 1);
      setSuggestedGroupsUrl(filteringURL);
    }
  }, []);

  useEffect(async () => {
    if (deactivateGroup.visible) {
      deactivateGroupRef.current.show({
        severity: deactivateGroup.type,
        summary: deactivateGroup.header,
        detail: deactivateGroup.detail,
        life: 5000,
      });
      if (deactivateGroup.type === "success") setToUpdate(true);
      setDeactivateGroup({
        ...deactivateGroup,
        visible: false,
      });
    }
  }, [deactivateGroup.visible]);

  useEffect(async () => {
    // Si une demande de rejoindre un groupe a été faite :
    if (joinGroup.result) {
      // On affiche le toast de confirmation
      if (joinGroup.result.status == 200) {
        groupJoinRef.current.show({
          severity: "success",
          summary: "Rejoindre un groupe",
          detail: joinGroup.private
            ? "Vous avez demandé à rejoindre ce groupe"
            : "Vous avez rejoint ce groupe",
          life: 5000,
        });
        // On met à jour les groupes et groupes de l'utilisateur
        setToUpdate(true);
        // On affiche le toast d'erreur
      } else {
        groupJoinRef.current.show({
          severity: "error",
          summary: "Erreur",
          detail: joinGroup.result,
          life: 5000,
        });
      }
      // On remet à null
      setJoinGroup({
        title: "",
        id: null,
        visible: false,
        state: null,
      });
    }
  }, [joinGroup.result]);

  useEffect(async () => {
    // Si une demande de quitter un groupe a été faite :
    if (leaveGroup.result) {
      // On affiche le toast de confirmation
      if (leaveGroup.result.status == 200) {
        groupLeaveRef.current.show({
          severity: "success",
          summary: "Succès",
          detail: "Vous avez quitté ce groupe avec succès",
          life: 5000,
        });
        // On met à jour les groupes et groupes de l'utilisateur
        setToUpdate(true);
        // On affiche le toast d'erreur
      } else {
        groupLeaveRef.current.show({
          severity: "error",
          summary: "Erreur",
          detail: leaveGroup.result,
          life: 5000,
        });
      }
    }
  }, [leaveGroup.result]);

  // ------------------------------------------------------------

  /**
   * Hook s'exécutant à chaque fois que les groupes sont mis à jour,
   * que ce soit depuis la recherche ou depuis la pagination
   */
  useMemo(() => {
    if (groupsQuery.loaded && isNotEmptyArray(groupsQuery.data))
      setSelectGroup(groupsQuery.data[0]);
    else setSelectGroup(null);
  }, [groupsQuery.loaded, groupsQuery.data]);

  // Reset des urls de filtre dans le state
  useEffect(() => {
    return () => {
      props.handleUpdateGroups({
        filteringURL: null,
        dataFromPagination: null,
      });
    };
  }, []);

  return (
    <div>
      <Toast ref={groupJoinRef} />
      <Toast ref={groupLeaveRef} />
      <Toast ref={deactivateGroupRef} />
      <Header
        activeIndex={props.activeIndex}
        setActiveIndex={props.setActiveIndex}
        items={props.items}
      >
        {props.auth.isAdmin ? (
          <NavBarTab
            select="groupesadmin"
            activeIndex={props.activeIndex}
            items={props.items}
          />
        ) : (
          <NavBarTab
            select="groupes"
            activeIndex={props.activeIndex}
            items={props.items}
          />
        )}
      </Header>
      <div className="groupe_container" key={groupsQuery}>
        <div className="groupe_container__title">
          <h1 className="h1_trait_dessus">
            {t("footerApresConnexion.li_groupes_echanges")}
          </h1>
          {/* <p> Sous-titres à définir</p> */}
          {props.auth.userConnected.userRoles.some(
            (role) => role.roleName == "ROLE_ADMIN_DU_PORTAIL"
          ) ? (
            <BtnBlanc
              btnTexte={t("groupManagement.createHeader")}
              btnAction={() =>
                props.handleMenu({
                  activeItemGroupesAdmin:
                    props.items.itemsGroupesAdmin.findIndex(
                      (v) => v.label == "Créer et gérer mes groupes"
                    ),
                })
              }
            >
              <FaCog />
            </BtnBlanc>
          ) : (
            <BtnBlanc
              btnTexte={t("groupManagement.proposeHeader")}
              btnAction={() =>
                props.handleMenu({
                  activeItemGroupes: props.items.itemsGroupes.findIndex(
                    (v) => v.label == "Proposer et gérer mes groupes"
                  ),
                })
              }
            >
              <FaCog />
            </BtnBlanc>
          )}
        </div>

        <div className="groupe_main_section">
          <div className="groupe_container_left">
            <GroupeMembership
              myGroups={groupsQuery}
              setSelectGroup={setSelectGroup}
              from="container"
              setLeaveGroup={setLeaveGroup}
            />
            <div className="groupe_container_left__suggestion">
              <h3>{t("groupManagement.suggestions")}</h3>
              {/* affichage des events filtrés */}
              {shuffledSuggestedGroups &&
                shuffledSuggestedGroups.map(
                  (group, index) =>
                    index < 3 && (
                      // affichage des events sur la page d'accueil (avec la props nbEvents)
                      <>
                        <GroupeCardSuggestion
                          key={group}
                          title={group.label}
                          description={group.description}
                          id={group.id}
                          url={group.imageUrl}
                          private={group.private}
                          setJoinGroup={setJoinGroup}
                        />
                      </>
                    )
                )}

              <div className="groupe_container_left__suggestion__button">
                {props.auth.userConnected.userRoles.some(
                  (role) => role.roleName == "ROLE_ADMIN_DU_PORTAIL"
                ) ? (
                  <BtnBleu
                    btnTexte={t("profil.more_groups")}
                    btnAction={() =>
                      props.handleMenu({
                        activeItemGroupesAdmin:
                          props.items.itemsGroupesAdmin.findIndex(
                            (v) => v.label == "Trouver un groupe"
                          ),
                      })
                    }
                  />
                ) : (
                  <BtnBleu
                    btnTexte={t("profil.more_groups")}
                    btnAction={() =>
                      props.handleMenu({
                        activeItemGroupes: props.items.itemsGroupes.findIndex(
                          (v) => v.label == "Trouver un groupe"
                        ),
                      })
                    }
                  />
                )}
              </div>
            </div>
          </div>
          <div className="groupe_container_right">
            {/* Affichage des détails du groupe sélectionné */}
            {groupsQuery.loaded ? (
              // Si le groupe sélectionné a été trouvé on l'affiche
              selectGroup ? (
                <GroupeDetails
                  groupId={selectGroup.id}
                  dataLoaded={groupsQuery.loaded}
                  setLeaveGroup={setLeaveGroup}
                  setDeactivateGroup={setDeactivateGroup}
                />
              ) : (
                // Sinon on montre que l'utilisateur n'appartient à aucun groupe
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "25vh",
                  }}
                >
                  {t("groupManagement.noSelected")}
                </div>
              )
            ) : (
              // Si les données du groupe sélectionné ne sont pas chargées on affiche un loader
              <Loader />
            )}
          </div>
        </div>
      </div>
      {/* Component pour afficher dialogs sur les groupes */}
      <ModalRejoindreGroupe visible={joinGroup} setVisible={setJoinGroup} />
      <ModalQuitterGroupe visible={leaveGroup} setVisible={setLeaveGroup} />
    </div>
  );
};

GroupeContainer.propTypes = {
  title: PropTypes.string,
  chapeau: PropTypes.string,
  bouton: PropTypes.string,
  auth: PropTypes.object,
  users: PropTypes.object,
  states: PropTypes.object,
  handleUpdateUsers: PropTypes.func,
  items: PropTypes.object,
  activeIndex: PropTypes.number,
  setActiveIndex: PropTypes.func,
  handleMenu: PropTypes.func,
  groups: PropTypes.object,
  handleUpdateGroups: PropTypes.func,
};

const mapStateToProps = (state) => ({
  items: state.items,
  auth: state.auth,
  groups: state.groups,
  users: state.users,
  states: state.states,
});

const mapDispatchToProps = (dispatch) => ({
  handleMenu: (value) => {
    dispatch(updateMenu(value));
  },
  handleUpdateGroups: (value) => {
    dispatch(updateGroups(value));
  },
  handleUpdateUsers: (value) => {
    dispatch(updateUsers(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupeContainer);
