import Management from "Components/Management/Management";
import TempPagination from "Components/Pagination/TempPagination";
import { updateEvents } from "Redux/Actions/eventsActions";
import { updatePagination } from "Redux/Actions/paginationActions";
import { useFetchGetPagination } from "Services/api";
import {
  filterByTransmitter,
  formatDate,
  formatDateFrenchLocale,
} from "Services/functions";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Evenement from "../EvenementCard/EvenementCard";
import EvenementManagementCard from "./EvenementManagementCard/EvenementManagementCard";
import "./EvenementsManagement.scss";
import { eventMgmtButtons } from "Components/Management/ManagementButtons";
import DataHandler from "Components/DataHandler/DataHandler";

/**
 * Permet d'afficher la page de gestion des événements
 */
const EvenementsManagement = (props) => {
  const { t } = useTranslation("common");
  const [url, setUrl] = useState(null);

  useFetchGetPagination(url, props.auth.token, props.handleUpdateEvents);
  // *****************************************************************
  // ****************** UseStates / UseRef *********************
  // *****************************************************************
  const EventMngRef = useRef(null);

  const [states, setStates] = useState([]);
  const [lastEvent, setLastEvent] = useState(null);
  const evenementsContainerRef = useRef(null);
  // State qui permet de gérer les boutons en fonction des clics
  const [btnList, setBtnList] = useState(
    eventMgmtButtons.map((x) => {
      return { ...x };
    })
  );
  //? On ne fait pas de requête si aucun état n'est sélectionné
  //? Des états par défaut sont appliqués dans un useEffect
  //! Permet d'éviter de faire deux requêtes au chargement de la page
  const eventsQuery = useFetchGetPagination(
    url,
    props.auth.token,
    props.handleUpdateEvents,
    true
  );

  // *****************************************************************
  // ****************** UseEffect *********************
  // *****************************************************************
  useEffect(() => {
    props.handleUpdateEvents({
      currentTransmitter: ["me"],
    });
    return () => {
      props.handleUpdateEvents({
        dataFromPagination: null,
      });
      props.handleUpdatePagination({ currentPage: 1 });
      props.handleUpdatePagination({ paginationBasicFirst: 0 });
      props.handleUpdatePagination({ paginationBasicRows: 12 });
    };
  }, []);

  useEffect(() => {
    //? remise à zéro si les états sont chargés
    //? et que l'url ne contient pas de state
    if (states && states?.length > 0 && !url?.href?.includes("state")) {
      if (EventMngRef.current !== null) {
        window.scroll({
          top: EventMngRef.current.offsetTop,
          behavior: "smooth",
        });
      }
    }
  }, [states]);

  // UseEffect de chargement
  useEffect(() => {
    /// Récupérer les states, et les filtrer par ordre alphabétique
    if (props.events.etats && props.events.etats.length > 0) {
      setStates([]);
      let tempArray = [];
      props.events.etats.forEach((state) => tempArray.push(state));
      tempArray = tempArray.sort((a, b) => a.name?.localeCompare(b.name));
      setStates(tempArray);
    }
  }, [props.events.etats]);

  // Hook qui se déclenche quand la liste des offres change
  useEffect(() => {
    // Charge la dernière offre en fonction de l'emetteur choisi
    eventsQuery?.data?.length > 0 &&
      url?.href?.includes("page=1") &&
      eventsQuery.data[0]?.state?.statusLabel !== "Brouillon" &&
      setLastEvent(eventsQuery.data[0]);
  }, [eventsQuery.data]);

  return (
    <div className="gestion_evenements" ref={EventMngRef}>
      <div className="gestion_evenements__title">
        <h1 className="h1_trait_dessus">
          {props.auth.isAdmin
            ? t("eventApresConnexion.submenuGererAdmin")
            : t("eventApresConnexion.submenuGererAlumni")}
        </h1>
        <Management
          states={states}
          btnEntreprise={btnList[2]}
          actualPage={"événement"}
          currentTransmitter={
            btnList.filter((btn) => btn.isActive === true)[0].transmitter
          }
          updateFunction={props.handleUpdateEvents}
          setUrl={setUrl}
        ></Management>
      </div>
      <div className="gestion_evenements__body">
        <div className="gestion_evenements__body__left">
          {props.auth.isAdmin &&
            btnList.map((btnStatus, index) => {
              return (
                <button
                  className={
                    btnStatus.isActive
                      ? "btn-bleu " + "item" + index
                      : "btn-blanc " + "item" + index
                  }
                  onClick={() => {
                    setUrl(
                      filterByTransmitter(
                        "events",
                        url,
                        btnList[index].transmitter,
                        props.auth.userConnected.id,
                        states.find((state) => state.name?.includes("Publié"))
                          ?.id
                      )
                    );
                    props.handleUpdateEvents({
                      currentTransmitter: btnList[index].transmitter,
                      dataFromPagination: null,
                    });
                    var i = 0;
                    setBtnList(
                      btnList.map((btn) => {
                        btn.isActive = i == index;
                        i++;
                        return btn;
                      })
                    );
                  }}
                  key={index}
                >
                  {btnList[index].text}
                </button>
              );
            })}
          <h3>{t("eventApresConnexion.manageDernierEvent")}</h3>
          {/* Affichage du dernier evenement */}
          {lastEvent !== null && lastEvent !== undefined && (
            <Link
              to={{
                pathname: `/event-detail/${lastEvent.id}`,
                params: lastEvent.id,
              }}
            >
              <Evenement
                categorie={lastEvent.category}
                debutDate={formatDate(lastEvent.startingAt, ".")}
                finDate={formatDate(lastEvent.endingAt, ".")}
                inscription={lastEvent.fulfilled}
                title={lastEvent.title}
                chapeau={lastEvent.description}
                eventDetails={lastEvent}
                url={lastEvent?.imageUrl ? lastEvent.imageUrl : "event1"}
                registerEnd={lastEvent.registerEnd}
              />
            </Link>
          )}
        </div>
        <div
          className="gestion_evenements__body__right"
          ref={evenementsContainerRef}
        >
          <DataHandler
            data={eventsQuery.data || null}
            error={eventsQuery.error || null}
            loaded={eventsQuery.loaded || false}
            emptyMessage={"Aucun événement ne correspond à votre recherche."}
            errorMessage={
              "Une erreur est survenue lors de la récupération des événements."
            }
          >
            {props.events.dataFromPagination?.data?.length > 0 &&
              props.events.dataFromPagination.data
                .filter(
                  (event) =>
                    event.state.statusLabel !== "Brouillon" ||
                    event.createdBy.id === props.auth.userConnected.id
                )
                .map((event, index) => (
                  <EvenementManagementCard
                    titre={event.title}
                    dateDebut={formatDateFrenchLocale(event.startingAt)}
                    dateFin={formatDateFrenchLocale(event.endingAt)}
                    participants={event.eventParticipants}
                    statut={event.state}
                    url={event?.imageUrl ? event.imageUrl : "event2"}
                    key={index}
                    id={event.id}
                    feedback={event.feedback?.feedback}
                    transmitterName={props.events.currentTransmitter[0]}
                  />
                ))}
          </DataHandler>
        </div>
      </div>

      <TempPagination
        result={eventsQuery}
        refItemParent={evenementsContainerRef}
        rows={20}
      />
    </div>
  );
};

EvenementsManagement.propTypes = {
  auth: PropTypes.object,
  nbActus: PropTypes.number,
  pageActus: PropTypes.string,
  filtre: PropTypes.string,
  filtres: PropTypes.array,
  setFiltre: PropTypes.func,
  events: PropTypes.any,
  handleUpdateEvents: PropTypes.func,
  pagination: PropTypes.object,
  handleUpdatePagination: PropTypes.func,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  events: state.events,
  pagination: state.pagination,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateEvents: (value) => {
    dispatch(updateEvents(value));
  },
  handleUpdatePagination: (value) => {
    dispatch(updatePagination(value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EvenementsManagement);
