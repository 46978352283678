import React, { useCallback, useState } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Modal from "../Modal";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import { useEffect } from "react";
import "./ModalDroitsAdminGroupe.scss";
import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import BtnBlanc from "Components/Boutons/BtnBlanc/btn-blanc";
import {
  useFetchGet,
  useFetchGetConditional,
  useFetchGetPagination,
} from "Services/api";
import { Dropdown } from "primereact/dropdown";
import { debounce } from "Services/debounce";
import { InputText } from "primereact/inputtext";
import { useTranslation } from "react-i18next";
import Loader from "Components/Loader/loader";

const ModalDroitsAdminGroupe = ({ visible, setVisible }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
  } = useForm({
    defaultValues: {
      member: null,
    },
  });

  const { t } = useTranslation("common");

  const authState = useSelector((state) => state.auth);
  const statesState = useSelector((state) => state.states);

  const memberStatuses = useFetchGetConditional(
    "/group_member_statuses",
    authState.token,
    statesState.groupMemberStatesData
  );

  const membersRoles = useFetchGet(
    "/roles",
    authState.token,
    statesState.rolesData
  );

  const [groupMembersUrl, setGroupMembersUrl] = useState(null);

  const [firstNameFilter, setFirstNameFilter] = useState("");
  const [surnameFilter, setSurnameFilter] = useState("");

  const groupMembersQuery = useFetchGetPagination(
    groupMembersUrl,
    authState.token,
    null
  );

  const buildGroupMembersURL = async (id) => {
    if (!id) return;
    const newURL = new URL(
      process.env?.REACT_APP_BASE_URL_API + "/group_members"
    );
    newURL.searchParams.append("groupSource", id);
    newURL.searchParams.append(
      "groupMemberStatus",
      memberStatuses.data.find((status) => status.label === "ACTIF").id
    );
    newURL.searchParams.append(
      "groupMemberRoles.role",
      membersRoles.data.find((role) => role.roleName === "ROLE_GROUP_USER").id
    );
    // On ré-assigne la nouvelle URL, avec une nouvelle référence
    setGroupMembersUrl(newURL.toString());
  };

  const debouncedUpdateUrl = useCallback(
    debounce((url) => {
      setGroupMembersUrl(url);
    }, 500),
    []
  );

  useEffect(() => {
    if (firstNameFilter || surnameFilter) {
      const url = new URL(groupMembersUrl);

      firstNameFilter &&
        url.searchParams.set("user.firstname", firstNameFilter);
      surnameFilter && url.searchParams.set("user.surname", surnameFilter);
      debouncedUpdateUrl(url.toString());
    }
  }, [firstNameFilter, surnameFilter]);

  useEffect(() => {
    if (visible?.group?.id && memberStatuses.loaded && membersRoles.loaded) {
      //? Récupération liste des membres du groupe qui n'ont pas le droit d'admin du groupe
      //? Et qui ne correspondent pas à l'utilisateur connecté
      buildGroupMembersURL(visible.group.id);
    }
  }, [visible, memberStatuses.loaded, membersRoles.loaded]);

  const promoteToAdmin = (data) => {
    var result = undefined;
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL_API}/group_members/admin/promote/${data.member.id}`,
        "{}",
        {
          headers: authState.token
            ? {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authState.token}`,
              }
            : {
                "Content-Type": "application/json",
              },
        }
      )
      .then((response) => (result = response))
      .catch((error) => (result = error.response))
      .finally(() => {
        if (result && result.status && result.status === 200)
          setVisible({ state: false, result: result });
        else
          setVisible({
            state: false,
            result: result,
          });
        reset();
      });
  };

  const onSubmit = (data) => {
    if (getValues("member")) promoteToAdmin(data);
  };

  /**
   * Afffichage d'une erreur si nécessaire dans le formulaire
   * @param {String} name nom du champ
   * @returns {FieldError, JSX.Element}
   */
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  return (
    <>
      <Modal
        visible={visible.state}
        setVisible={() => setVisible({ ...visible, state: false })}
        header="Ajout droits d'administrateur"
        height="fit-content"
        width="50%"
        className="modal modal_ajout-droits"
        blockScroll={true}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={"ajout_droits__form"}
        >
          <label>Filtres</label>
          <div className="ajout_droits__form-filters">
            <InputText
              placeholder={t("profilEdition.personalInfo.prenom")}
              className="ajout_droits__form-content"
              onChange={(e) => setFirstNameFilter(e.target.value)}
              value={firstNameFilter}
            />
            <InputText
              placeholder={t("profilEdition.personalInfo.nom")}
              className="ajout_droits__form-content"
              onChange={(e) => setSurnameFilter(e.target.value)}
              value={surnameFilter}
            />
          </div>

          {groupMembersQuery.loaded ? (
            groupMembersQuery.error ? (
              <div>{t("groupManagement.apiFailure")}</div>
            ) : (
              <>
                <label htmlFor="content">{t("header.global_search")}</label>
                <div className="ajout_droits__form-results">
                  <Controller
                    name="member"
                    control={control}
                    rules={{
                      required: "Veuillez choisir un utilisateur",
                    }}
                    render={({ field }) => (
                      <Dropdown
                        {...field}
                        options={groupMembersQuery.data
                          ?.map((member) => ({
                            ...member,
                            displayLabel: `${member?.user?.firstname} ${member?.user?.surname}`,
                          }))
                          .sort((a, b) =>
                            a.displayLabel.localeCompare(b.displayLabel)
                          )}
                        optionLabel="displayLabel"
                        className="ajout_droits__form-content"
                      />
                    )}
                  />
                  {getFormErrorMessage("member")}
                </div>
                <div className="ajout_droits__form-buttons">
                  <BtnBleu
                    btnTexte={t("profilEdition.validate")}
                    btnAction={() => {
                      onSubmit(getValues());
                    }}
                  />
                  <BtnBlanc
                    btnTexte={t("common.forms.cancel_btn")}
                    action={"button"}
                    btnAction={() => {
                      setVisible({ ...visible, state: false });
                      reset();
                    }}
                  />
                </div>
              </>
            )
          ) : (
            <Loader />
          )}
        </form>
      </Modal>
    </>
  );
};

ModalDroitsAdminGroupe.propTypes = {
  visible: PropTypes.object.isRequired,
  setVisible: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  states: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  states: state.states,
});

export default connect(mapStateToProps)(ModalDroitsAdminGroupe);
