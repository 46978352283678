import axios from "axios";
import { useState, useEffect, useMemo } from "react";
import { store } from "Redux/store";

export const useFetchGetPagination = (
  url,
  token = null,
  updateFunc = null,
  mustReset = false
) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  let response = null;

  const reset = () => {
    setError("");
    setLoaded(false);
    setTotalItems(0);
  };

  useMemo(() => {
    if (!url) return;

    if (mustReset) reset();

    if (typeof url === "string") url = new URL(url);
    url?.searchParams?.set("page", page);
    url !== "" &&
      url &&
      axios
        .get(url, {
          headers: token && {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data?.data) {
            setData(res.data.data);
            setTotalItems(res.data.totalItems);
          } else {
            setData(res.data["hydra:member"]);
            setTotalItems(res.data["hydra:totalItems"]);
          }
          response = res;
        })
        .catch((error) => {
          setError(error.message);
        })

        .finally(() => {
          setLoaded(true);
          updateFunc &&
            updateFunc({
              dataFromPagination: {
                data: response?.data?.data ?? response?.data["hydra:member"],
                loaded: true,
                error: error ? error : "",
                page,
                setPage,
                totalItems:
                  response?.data?.totalItems ??
                  response?.data["hydra:totalItems"],
              },
            });
        });
  }, [page, url]);

  return {
    data,
    error,
    loaded,
    page,
    setPage,
    totalItems,
  };
};

/**
 * Fonction qui permet de récupérer les données de l'API
 * sans passer par un Hook react
 * @param {String} url - Url vers laquelle faire la requête
 * @param {String} token - Token de l'utilisateur
 * @param {*} params - Paramètres de la requête
 */
export const fetchGet = async (
  url,
  token = null,
  params = null,
  back = "univ"
) => {
  var data = undefined;
  if (
    back == "nexus" &&
    (!process.env.REACT_APP_NEXUS_URL_API ||
      process.env.REACT_APP_NEXUS_URL_API == undefined)
  )
    return null;
  const apiUrl =
    back === "univ"
      ? process.env.REACT_APP_BASE_URL_API
      : process.env.REACT_APP_NEXUS_URL_API;
  try {
    let response = await axios.get(`${apiUrl}${url}`, {
      headers: token
        ? {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          }
        : {
            accept: "application/json",
          },
      params: params,
    });
    data = response.data;
  } catch (error) {
    console.log(error);
    data = error;
  }
  return data;
};

/**
 * Fonction qui permet de modifier des données à l'API sans passer par un Hook react
 * @param {String} url - Url vers laquelle faire la requête
 * @param {*} payload - Données POST à envoyer
 * @returns {Object} - Réponse de l'API
 */
export const fetchPut = async (url, payload) => {
  let data = null;
  let error = null;
  const token = store.getState().auth.token;

  await axios
    .put(`${process.env.REACT_APP_BASE_URL_API}${url}`, payload, {
      headers: token
        ? {
            accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          }
        : {
            accept: "application/json",
          },
    })
    .then((response) => {
      data = response.data;
    })
    .catch((e) => (error = e));
  return { data, error };
};

/**
 * Fonction qui permet de delete une entité avec l'API
 * sans passer par un Hook react
 * @param {String} url - Url vers laquelle faire la requête
 * @param {String} token - Token de l'utilisateur
 * @param {*} params - Paramètres de la requête
 */
export const fetchDelete = async (url, token = null, params = null) => {
  var data = undefined;
  try {
    let response = await axios.delete(
      `${process.env.REACT_APP_BASE_URL_API}${url}`,
      {
        headers: token
          ? {
              accept: "application/json",
              Authorization: `Bearer ${token}`,
            }
          : {
              accept: "application/json",
            },
        params: params,
      }
    );
    data = response.data || response;
  } catch (error) {
    console.log(error);
    data = error;
  }
  return data;
};

/**
 * Fonction qui permet de poster des données à l'API
 * sans passer par un Hook react
 * @param {String} url - Url vers laquelle faire la requête
 * @param {*} payload - Données POST à envoyer
 * @param {String} token - Token de l'utilisateur
 * @param {*} params - Paramètres de la requête
 * @returns
 */
export const fetchPost = async (
  url,
  payload,
  token = null,
  params = null,
  type = null
) => {
  var data = undefined;
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_BASE_URL_API}${url}`,
      payload,
      {
        headers: token
          ? {
              accept: "application/json",
              "Content-Type": type ? type : "application/json",
              Authorization: `Bearer ${token}`,
            }
          : {
              accept: "application/json",
            },
        params: params,
      }
    );
    data = response.data;
  } catch (e) {
    console.log(e);
    data = e;
  }
  return data;
};

export const useFetchGet = (
  url,
  token = null,
  params = null,
  back = "univ"
) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (
      back == "nexus" &&
      (!process.env.REACT_APP_NEXUS_URL_API ||
        process.env.REACT_APP_NEXUS_URL_API == undefined)
    ) {
      setData(null);
      setLoaded(true);
      return;
    }
    const apiUrl =
      back === "univ"
        ? process.env.REACT_APP_BASE_URL_API
        : process.env.REACT_APP_NEXUS_URL_API;
    axios
      .get(`${apiUrl}${url}`, {
        headers: token
          ? {
              accept: "application/json",
              Authorization: `Bearer ${token}`,
            }
          : {
              accept: "application/json",
            },
        params: params,
      })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => setError(error.message))
      .finally(() => setLoaded(true));
  }, []);
  return { data, error, loaded };
};

export const useFetchGetConditional = (url, token = null, reduxData) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (
      // False check basique
      !reduxData ||
      // Check si c'est un tableau vide
      reduxData.length === 0 ||
      // Check si c'est un objet vide
      (Object.keys(reduxData).length === 0 && reduxData.constructor === Object)
    )
      axios
        .get(`${process.env.REACT_APP_BASE_URL_API}${url}`, {
          headers: token
            ? {
                accept: "application/json",
                Authorization: `Bearer ${token}`,
              }
            : {
                accept: "application/json",
              },
        })
        .then((response) => {
          setData(response.data);
        })
        .catch((error) => setError(error.message))
        .finally(() => setLoaded(true));
    else {
      setData(reduxData);
      setLoaded(true);
    }
  }, []);
  return { data, error, loaded };
};

export const useFetchGetWihoutApiInUrl = (url, token = null) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL_API}${url}`, {
        headers: token
          ? {
              accept: "application/json",
              Authorization: `Bearer ${token}`,
            }
          : {
              accept: "application/json",
            },
      })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => setError(error.message))
      .finally(() => setLoaded(true));
  }, []);
  return { data, error, loaded };
};

export const useFetchPost = (url, token = null, payload) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL_API}${url}`, payload, {
        headers: token
          ? {
              accept: "application/json",
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            }
          : {
              accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
      })
      .then((response) => setData(response.data))
      .catch((error) => setError(error.message))
      .finally(() => setLoaded(true));
  }, []);

  return { data, error, loaded };
};

export const useFetchPut = (url, token = null, payload) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    axios
      .put(`${process.env.REACT_APP_BASE_URL_API}${url}`, payload, {
        headers: token
          ? {
              accept: "application/json",
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            }
          : {
              accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
      })
      .then((response) => setData(response.data))
      .catch((error) => setError(error.message))
      .finally(() => setLoaded(true));
  }, []);

  return { data, error, loaded };
};

export const callAPIAlumni = (url, body, toast = null) => {
  axios
    .post(`${process.env.REACT_APP_BASE_URL_API}${url}`, body)
    .then((res) => {
      toast &&
        toast.current.show({
          severity: "success",
          summary: "Succès : ",
          detail: typeof res.data === "string" ? res.data : "Succès",
          life: 4000,
        });
    })
    .catch((error) => {
      toast &&
        toast.current.show({
          severity: "error",
          summary: "Erreur : ",
          detail: error?.message || "Une erreur est survenue",
          life: 4000,
        });
    });
};
