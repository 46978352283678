import React, { useEffect, useState } from "react";
import Header from "../../../Components/Header/Header";
import Footer from "../../../Components/Footer/Footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import { fetchGet } from "../../../Services/api";
import Loader from "../../../Components/Loader/loader";
import { useSelector } from "react-redux";
import "./Groupe.scss";
import banniere_groupe from "../../../assets/banniere_groupe.webp";
import ModalQuitterGroupe from "../../../Components/Modal/ModalQuitterGroupe/ModalQuitterGroupe";
import ModalRejoindreGroupe from "../../../Components/Modal/ModalRejoindreGroupe/ModalRejoindreGroupe";
import {
  formatDate,
  formatDateLocale,
  isNotEmptyArray,
  sanitizeHtml,
} from "../../../Services/functions";
import parse from "html-react-parser";
import { HTMLParseOptions } from "../../../Services/htmlParseOptions";
import { useTranslation } from "react-i18next";
import GroupeMembers from "../../../Components/Groupes/GroupeMembers/GroupeMembers";
import BtnBlanc from "../../../Components/Boutons/BtnBlanc/btn-blanc";
import GroupeCardEvent from "../../../Components/Groupes/GroupeCard/GroupeCardEvent/GroupeCardEvent";
import { DateTime } from "luxon";
import GroupePublication from "../../../Components/Groupes/GroupePublication/GroupePublication";
import { Divider } from "primereact/divider";

/**
 * Permet d'accéder à la page d'un groupe (/groupe-details/:id)
 */
const Groupe = () => {
  const { id } = useParams();

  // Translations
  const { t } = useTranslation("common");

  // State management
  const [groupe, setGroupe] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Redux state
  const authState = useSelector((state) => state.auth);
  const i18nState = useSelector((state) => state.i18n);

  // Group related states
  const [isGroupAdmin, setIsGroupAdmin] = useState(false);
  const [isGroupMember, setIsGroupMember] = useState(false);
  const [numberOfMembers, setNumberOfMembers] = useState(0);

  // Modal management
  const [joinGroup, setJoinGroup] = useState({
    title: "",
    id: null,
    visible: false,
    state: null,
    result: null,
  });

  const [leaveGroup, setLeaveGroup] = useState({
    title: "",
    id: null,
    visible: false,
    state: null,
    result: null,
  });

  const [displayMembersModal, setDisplayMembersModal] = useState(false);

  // ------------- Fetch group details -------------

  /**
   * Retrieve group details from API
   * @param {any} groupId
   * @returns {Promise<void>}
   */
  const getGroup = async (groupId) => {
    // Reset previous states
    setError(null);
    setIsLoading(true);
    try {
      // Validate ID more robustly
      const parsedId = parseInt(groupId, 10);
      if (isNaN(parsedId)) {
        throw new Error("Invalid group ID");
      }

      const res = await fetchGet(`/groups/${parsedId}`, authState.token);
      if (res?.state?.statusLabel !== "Actif") {
        throw new Error("Vous n'avez pas accès à ce groupe");
      }
      setGroupe(res);
    } catch (error) {
      // More informative error handling
      setError(error.message || t("groupDetails.error"));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(async () => {
    if (id) {
      await getGroup(id);
    }
  }, [id]);

  // ------------- Group members -------------

  /**
   * Vérifie si l'utilisateur connecté est admin du groupe ou non
   * - Si admin du portail, il est admin du groupe
   * - Sinon, on regarde dans la liste des membres du groupe, s'il figure en tant qu'admin (ROLE_GROUP_ADMIN)
   * @returns {Promise<void>}
   */
  const isCurrentUserGroupAdmin = async () => {
    if (authState.isAdmin) {
      setIsGroupAdmin(true);
      return;
    }
    const user = await fetchGet("/group_members", authState.token, {
      groupSource: groupe.id,
      user: authState.userConnected.id,
    });
    if (!user) setIsGroupAdmin(false);
    else {
      const isGroupAdmin = user.groupMemberRoles.some(
        (groupMember) => groupMember.role.roleName === "ROLE_GROUP_ADMIN"
      );
      const isGroupMember = user.groupMemberRoles.some(
        (groupMember) => groupMember.role.roleName === "ROLE_GROUP_MEMBER"
      );
      setIsGroupAdmin(isGroupAdmin);
      setIsGroupMember(isGroupMember);
    }
  };

  /**
   * Récupère le nombre de membres actifs dans le groupe
   * @returns {Promise<void>}
   */
  const getNumberOfMembers = async () => {
    const numbersCount = await fetchGet(
      `/groups/${groupe.id}/number_of_active_members_in_the_group`,
      authState.token
    );
    setNumberOfMembers(numbersCount);
  };

  useEffect(async () => {
    if (groupe) {
      await isCurrentUserGroupAdmin();
      await getNumberOfMembers();
    }
  }, [groupe]);

  // ------------- Notifications on join/leave group -------------
  useEffect(async () => {
    // Si une demande de rejoindre un groupe a été faite :
    if (joinGroup.result) {
      // On affiche le toast de confirmation
      if (joinGroup.result.status === 200) {
        authState.toast.current.show({
          severity: "success",
          summary: "Rejoindre un groupe",
          detail: joinGroup.private
            ? "Vous avez demandé à rejoindre ce groupe"
            : "Vous avez rejoint ce groupe",
          life: 5000,
        });
        // On affiche le toast d'erreur
      } else {
        authState.toast.current.show({
          severity: "error",
          summary: "Erreur",
          detail: joinGroup.result,
          life: 5000,
        });
      }
      // On remet à null
      setJoinGroup({
        title: "",
        id: null,
        visible: false,
        state: null,
      });
      await isCurrentUserGroupAdmin();
    }
  }, [joinGroup.result]);

  useEffect(async () => {
    // Si une demande de quitter un groupe a été faite :
    if (leaveGroup.result) {
      // On affiche le toast de confirmation
      if (leaveGroup.result.status === 200) {
        authState.toast.current.show({
          severity: "success",
          summary: "Succès",
          detail: "Vous avez quitté ce groupe avec succès",
          life: 5000,
        });
        // On affiche le toast d'erreur
      } else {
        authState.toast.current.show({
          severity: "error",
          summary: "Erreur",
          detail: leaveGroup.result,
          life: 5000,
        });
      }
      // On remet à null
      setLeaveGroup({
        title: "",
        id: null,
        visible: false,
        state: null,
      });
      await isCurrentUserGroupAdmin();
    }
  }, [leaveGroup.result]);

  // Navigate (router-dom)
  const navigate = useNavigate();

  // Group events
  const [groupEvents, setGroupEvents] = useState([]);

  useEffect(async () => {
    if (groupe?.id) {
      let groupEvents = [];
      groupEvents = await fetchGet("/event_groups", authState?.token, {
        associatedGroup: groupe.id,
        "startingAt[after]": DateTime.local().toISO(),
      });
      setGroupEvents(groupEvents);
    }
  }, [groupe?.id]);

  return (
    <div>
      <Header />
      <h1 className="evenement_detail_container__title h1_trait_dessus">
        {t("groupDetails.title")}
      </h1>
      <div className="return-groups">
        <BtnBlanc
          btnTexte={t("groupDetails.backToGroups")}
          btnAction={() => navigate("/groupes")}
        />
      </div>
      {groupe?.id && (
        <div className={"group-details"}>
          <div className={"group-details__header"}>
            <div className={"group-details__header-banner"}>
              <img
                src={groupe?.imageUrl ? `${groupe.imageUrl}` : banniere_groupe}
                alt="Bannière du groupe"
                title="Bannière du groupe"
              />
            </div>
            <div className={"group-details__header-title"}>
              <h2>{groupe.label}</h2>
              {/* If not admin, display either join or leave group */}
              {!isGroupAdmin &&
                (!isGroupMember ? (
                  <button
                    className="group__join-leave"
                    onClick={() => {
                      setJoinGroup({
                        title: groupe.label,
                        state: true,
                        id: groupe.id,
                        private: groupe.private,
                      });
                    }}
                  >
                    Rejoindre le groupe
                  </button>
                ) : (
                  <button
                    className="group__join-leave"
                    onClick={() => {
                      setLeaveGroup({
                        title: groupe.label,
                        state: true,
                        id: groupe.id,
                      });
                    }}
                  >
                    Quitter le groupe
                  </button>
                ))}
            </div>
            <div className={"group-details__header-infos"}>
              <p>
                {groupe?.public ? (
                  <React.Fragment>
                    <i className="pi pi-lock-open icon_infos"></i>
                    {t("createGroup.public")}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <i className="pi pi-lock icon_infos"></i>
                    {t("createGroup.private")}
                  </React.Fragment>
                )}
              </p>
              <p onClick={() => setDisplayMembersModal(true)} className="click">
                {numberOfMembers !== null ? (
                  <i className="pi pi-users icon_infos">
                    {numberOfMembers > 1
                      ? " " +
                        numberOfMembers +
                        " " +
                        t("groupManagement.members")
                      : " " +
                        numberOfMembers +
                        " " +
                        t("groupManagement.member")}
                  </i>
                ) : (
                  <i className="pi pi-spin pi-spinner icon_infos"></i>
                )}
              </p>
              <p>
                {i18nState?.language === "en"
                  ? "Created on " +
                    formatDateLocale(groupe.createdAt, i18nState?.language)
                  : "Créé le " +
                    formatDateLocale(groupe.createdAt, i18nState?.language)}
              </p>
            </div>
            <div className={"group-details__body"}>
              <div className={"group-details__body-description"}>
                <h4>{t("groupDetails.description")}</h4>
                {
                  // Parse and sanitize HTML from API
                  groupe.description &&
                    parse(sanitizeHtml(groupe.description), HTMLParseOptions)
                }
              </div>
              {isNotEmptyArray(groupEvents) && groupEvents[0]?.title && (
                <>
                  <h6>{t("groupEvent.nextEvent")}</h6>
                  <Link
                    to={"/group_event-detail/" + groupEvents[0].id}
                    key={groupEvents[0]}
                  >
                    <GroupeCardEvent
                      image={groupEvents[0].imageUrl ? "link" : "img1"}
                      link={groupEvents[0].imageUrl}
                      titre={groupEvents[0].title}
                      dateDebut={formatDate(groupEvents[0].startingAt, ".")}
                      dateFin={formatDate(groupEvents[0].endingAt, ".")}
                      participants={groupEvents[0].maxNumberOfParticipants}
                    />
                  </Link>
                </>
              )}
            </div>
            {!groupe.private || isGroupMember || isGroupAdmin ? (
              // Display group publications only if group is public or user is member/admin
              <GroupePublication
                currentGroupId={groupe.id}
                isGroupAdmin={isGroupAdmin}
              />
            ) : (
              <>
                <Divider />
                <div className={"group-details__body-unauthorized"}>
                  {t("groupDetails.unauthorizedPublications")}
                </div>
              </>
            )}
          </div>
          <ModalQuitterGroupe visible={leaveGroup} setVisible={setLeaveGroup} />
          <ModalRejoindreGroupe visible={joinGroup} setVisible={setJoinGroup} />
          {displayMembersModal && (
            <GroupeMembers
              id={groupe?.id}
              title={groupe?.label}
              openModal={displayMembersModal}
              setOpenModal={setDisplayMembersModal}
            />
          )}
        </div>
      )}
      {isLoading && <Loader />}
      {error && <p className={"group-details-error"}>{error}</p>}
      <Footer />
    </div>
  );
};

export default Groupe;
