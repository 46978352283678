import React, { useState, useRef, useEffect } from "react";
import "./InformationsPerso.scss";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { InputTextarea } from "primereact/inputtextarea";
import data from "assets/countries";
import BtnEcouteMarche from "Components/Boutons/BtnEcouteMarche/BtnEcouteMarche";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fr } from "date-fns/locale";
import { Checkbox } from "primereact/checkbox";
import NavBarTab from "Components/NavBar/NavBarTab/NavBarTab";
import ProfilBarShowApercu from "Components/ProfilBar/ProfilBarShow/ProfilBarShowAperçu/ProfilBarShowApercu";
import BtnBleuLeft from "Components/Boutons/BtnBleuLeft/btn-bleu-left";
import { updateAuth, updateCompletionLevel } from "Redux/Actions/authActions";
import { FaCheck } from "react-icons/fa";
import { Toast } from "primereact/toast";
import axios from "axios";
import ChargementImage from "Components/ChargementImage/ChargementImage";
import {
  changeOrderDateWithDay,
  convert,
  formatDate,
} from "Services/functions";
import BtnRetour from "Components/Boutons/BtnRetour/btn-retour";
import { CgClose } from "react-icons/cg";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-number-input/input";
import { useTranslation } from "react-i18next";
import Loader from "Components/Loader/loader";
import { errorToast, successToast } from "Services/toastFunctions";

registerLocale("fr", fr);

const InformationsPerso = (props) => {
  const [formData, setFormData] = useState(props.user);
  const [modified, setModified] = useState(true);
  const [filtreDate, setFiltreDate] = useState(props.user?.birthday);

  const { t } = useTranslation("common");

  const isInitialMount = useRef(true);

  // ------- TOASTS ----------
  // Toast de succès d'upload d'image
  const uploadToast = useRef(null);
  // Toast de suppression de l'image upload
  const cancelToast = useRef(null);

  // ------- VARIABLES GESTION IMAGES ----------
  // La photo vient-elle de la photothèque ?
  const [phototheque, setPhototheque] = useState(false);
  // Contiendra l'url de l'image choisie (upload ou photothèque)
  const [imageFile, setImageFile] = useState("");
  // Contiendra le fichier upload par l'utilisateur
  const [image, setImage] = useState("");
  // Id de l'image choisie (phototheque)
  const [imageId, setImageId] = useState("");

  const usersState = useSelector((state) => state.users);

  const [isProcessing, setIsProcessing] = useState(false);

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    reset,
  } = useForm({});

  const onSubmit = (data) => {
    setFormData({ ...data });
    setModified(false);
  };

  useEffect(() => {
    if (!props.user || !isInitialMount.current) return;
    reset({
      ...props.user,
    });
    setPhototheque(!props.user?.imageUrl);
    setImage(props.user?.imageUrl || "");
    isInitialMount.current = false;
  }, [props.user]);

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  /**
   * Remplissage de la key image selon différentes conditions
   * @param {Object} data données pour la requêtes
   * @returns {Object}
   */
  const fillImage = (data) => {
    // Si une image a été téléchargée, on met son url local dans la key imageFile
    if (imageFile && !phototheque) {
      data.imageFile = imageFile;
      delete data.imageStockId;
    }
    // Sinon, on met l'url de l'image choisie de la photothèque
    else if (phototheque) {
      data.imageStockId = imageId;
      delete data.imageFile;
      delete data.image;
    }
    return data;
  };

  const putImageStock = async (id) => {
    let url = `${process.env.REACT_APP_BASE_URL_API}/users/${props.user.id}/updateImageStock`;
    let data = {
      imageStockId: id,
    };
    axios
      .post(url, data, {
        headers: {
          Authorization: `Bearer ${props.auth.token}`,
        },
      })
      .then((res) => {
        if (res.status === 201) {
          setImage("");
          setImageFile("");
          setImageId("");
          setPhototheque(false);
        }
      })
      .catch((err) =>
        errorToast(err?.response?.data?.detail || "Une erreur est survenue")
      );
  };

  const putPicture = async (imageSrc) => {
    let url = `${process.env.REACT_APP_BASE_URL_API}/users/${props.user.id}/updatePicture`;
    let formData = new FormData();
    formData.append("imageFile", imageSrc);
    axios
      .post(url, formData, {
        headers: props.auth.token
          ? {
              accept: "application/json",
              "Content-Type": "multipart/form-data",

              Authorization: `Bearer ${props.auth.token}`,
            }
          : {
              accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
      })
      .then((res) => {
        if (res.status === 201) {
          setImage("");
          setImageFile("");
          setImageId("");
          setPhototheque(false);
        }
      })
      .catch((err) =>
        errorToast(err?.response?.data?.detail || "Une erreur est survenue")
      );
  };

  /**
   * It changes the order of the date, fills the image, and deletes the unnecessary fields
   * @param values - The values of the form.
   * @returns the values after the verification.
   */
  function verifyFields(values) {
    if (values.birthday)
      values.birthday = changeOrderDateWithDay(values.birthday);
    values = fillImage(values);
    ["id", "imageUrl", "imageFile", "imageStockId"].forEach((key) => {
      delete values[key];
    });
    return values;
  }

  /**
   * It updates the user's profile
   */
  const onUpdate = async () => {
    //? Remove id de l'objet props.user et modification l'objet du CV
    setIsProcessing(true);
    try {
      // let userObj = { ...formData };
      let userObj = verifyFields(getValues());
      if (userObj.birthday === "") userObj.birthday = null;

      const cVId = userObj.resume.id;
      isNaN(cVId)
        ? (userObj.resume = cVId)
        : (userObj.resume = `/api/cvs/${cVId}`);

      // MAJ de l'image, soit par upload, soit par photothèque via ses propres endpoints
      if (imageFile && imageFile !== props.user.imageUrl)
        await putPicture(imageFile);
      else if (
        (imageId &&
          phototheque &&
          props.user.imageUrl &&
          imageId !==
            props.user.imageUrl.slice(
              props.user.imageUrl.lastIndexOf("/") + 1
            )) ||
        !props.user.imageUrl
      )
        await putImageStock(imageId);
      const updateUser = await axios.put(
        `${process.env.REACT_APP_BASE_URL_API}/users/${props.user.id}`,
        userObj,
        {
          headers: {
            Authorization: `Bearer ${props.auth.token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (updateUser.status === 200) {
        // Requête pour récupérer les informations de l'user depuis l'API
        const updatedUser = await axios.get(
          `${process.env.REACT_APP_BASE_URL_API}/users/${props.user.id}/private`,
          {
            headers: {
              Authorization: `Bearer ${props.auth.token}`,
              "Content-Type": "application/json",
            },
          }
        );
        updatedUser.data &&
          props.handleUpdateAuth({
            userConnected: {
              ...updatedUser.data,
              birthday: formatDate(updatedUser.data.birthday),
            },
          });
        // Requête pour MAJ le niveau de complétion du profil
        const updateCompletionLevel = await axios.get(
          `${process.env.REACT_APP_BASE_URL_API}/users/${props.user.id}/profile_completion_rate`,
          {
            headers: {
              Authorization: `Bearer ${props.auth.token}`,
              "Content-Type": "application/json",
            },
          }
        );

        updateCompletionLevel.data &&
          props.handleUpdateCompletionLevel(updateCompletionLevel.data);
        successToast(
          "Votre mise à jour a bien été prise en compte",
          "Mise à jour effectuée avec succès"
        );
        setModified(true);
      }
    } catch (error) {
      errorToast(error?.response?.data?.detail || "Une erreur est survenue");
    }
    setIsProcessing(false);
  };

  const [isVisibleAdress, setIsVisibleAdress] = useState(false);
  const [listOfAdress, setListOfAdress] = useState([]);
  const [currentCountry, setCurrentCountry] = useState(getValues("country"));

  const getAdress = (adress) => {
    let listOfAdresses = [];
    axios
      .get(
        `https://api-adresse.data.gouv.fr/search/?q=${adress}&type=housenumber&autocomplete=1`
      )
      .then((res) => {
        res.data.features.forEach((adress) =>
          listOfAdresses.push([
            adress.properties.label,
            adress.properties.context,
            adress.properties.city,
          ])
        );
        setListOfAdress(listOfAdresses);
      });
  };

  return (
    <div className="edit_profil">
      <Toast ref={cancelToast} />
      <Toast ref={uploadToast} />
      {props.auth.isConnected && (
        <div className="article_actu">
          <NavBarTab
            id="desktop"
            select="profiledit"
            activeIndex={props.activeIndex}
            items={props.items}
          />
          <BtnEcouteMarche items={props.items} />

          <div className="edit_profil__title">
            <div>
              <h1 className="h1_trait_dessus">
                {t("profilEdition.personalInfo.header")}
              </h1>
              <p>{t("profilEdition.personalInfo.subtitle")}</p>
            </div>
            <BtnRetour />
          </div>
          {props.user ? (
            <div className="form-demo profil-form-demo">
              {modified ? (
                <form
                  className="p-fluid edit_profil__informationPersoForm"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="field">
                    <div
                      className="field__photoProfil"
                      key={props.auth.userConnected?.imageUrl}
                    >
                      <ChargementImage
                        label={t("profilEdition.personalInfo.profilePicture")}
                        image={image}
                        setImage={setImage}
                        imageFile={imageFile}
                        setImageFile={setImageFile}
                        setImageId={setImageId}
                        uploadToast={uploadToast}
                        cancelToast={cancelToast}
                        phototheque={phototheque}
                        setPhototheque={setPhototheque}
                        path="users"
                        currentImage={props.auth?.userConnected?.imageUrl}
                      />
                    </div>
                  </div>
                  {/* <h1>Ajouter une photo de profil</h1> */}
                  <div className="field">
                    <h2>{t("profilEdition.personalInfo.profileTitle")} *</h2>
                    <Controller
                      name="profilTitle"
                      control={control}
                      rules={{
                        required: t(
                          "profilEdition.personalInfo.profileTitleValidation"
                        ),
                      }}
                      render={({ field, fieldState }) => (
                        <InputText
                          id={field.name}
                          placeholder={t(
                            "profilEdition.personalInfo.profileTitlePlaceholder"
                          )}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                        />
                      )}
                    />

                    {getFormErrorMessage("profilTitle")}
                  </div>
                  <div className="form_columns">
                    <div className="field">
                      <h2>{t("profilEdition.personalInfo.nom")} *</h2>
                      <Controller
                        name="surname"
                        control={control}
                        rules={{
                          required: t(
                            "profilEdition.personalInfo.nomValidation"
                          ),
                        }}
                        render={({ field, fieldState }) => (
                          <InputText
                            disabled={true}
                            id={field.name}
                            placeholder="Dupont..."
                            {...field}
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                          />
                        )}
                      />

                      {getFormErrorMessage("surname")}
                    </div>
                    <div className="field">
                      <h2>{t("profilEdition.personalInfo.prenom")} *</h2>
                      <Controller
                        name="firstname"
                        control={control}
                        rules={{
                          required: t(
                            "profilEdition.personalInfo.prenomValidation"
                          ),
                        }}
                        render={({ field, fieldState }) => (
                          <InputText
                            disabled={true}
                            id={field.name}
                            placeholder="Marie, Arthur..."
                            {...field}
                            //   autoFocus
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                          />
                        )}
                      />
                      {getFormErrorMessage("firstname")}
                    </div>
                  </div>
                  <div className="field">
                    <h2>{t("profilEdition.personalInfo.description")}</h2>
                    <Controller
                      name="profilDescription"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputTextarea
                          id={field.name}
                          placeholder={t(
                            "profilEdition.personalInfo.descriptionPlaceholder"
                          )}
                          {...field}
                          //   autoFocus
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          rows={5}
                        />
                      )}
                    />
                    {getFormErrorMessage("profilDescription")}
                  </div>

                  <div className="form_columns">
                    <div className="field">
                      <h2>{t("profilEdition.personalInfo.linkedIn")}</h2>
                      <Controller
                        name="linkedinAccount"
                        control={control}
                        render={({ field, fieldState }) => (
                          <InputText
                            id={field.name}
                            placeholder="www.linkedin.com/in/..."
                            {...field}
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                          />
                        )}
                      />
                      {getFormErrorMessage("linkedinAccount")}
                    </div>
                    <div className="field">
                      <h2>{t("profilEdition.personalInfo.facebook")}</h2>
                      <Controller
                        name="facebookAccount"
                        control={control}
                        render={({ field, fieldState }) => (
                          <InputText
                            id={field.name}
                            placeholder="www.facebook.com/..."
                            {...field}
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                          />
                        )}
                      />
                      {getFormErrorMessage("facebookAccount")}
                    </div>
                  </div>
                  <div className="form_columns">
                    <div className="field">
                      <h2>{t("profilEdition.personalInfo.instagram")}</h2>
                      <Controller
                        name="instagramAccount"
                        control={control}
                        render={({ field, fieldState }) => (
                          <InputText
                            ref={register}
                            id={field.name}
                            placeholder="www.instagram.com/..."
                            {...field}
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                          />
                        )}
                      />
                      {getFormErrorMessage("instagramAccount")}
                    </div>
                    <div className="field">
                      <h2>{t("profilEdition.personalInfo.twitter")}</h2>
                      <Controller
                        name="twitterAccount"
                        control={control}
                        render={({ field, fieldState }) => (
                          <InputText
                            id={field.name}
                            placeholder="www.twitter.com/..."
                            {...field}
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                          />
                        )}
                      />
                      {getFormErrorMessage("twitterAccount")}
                    </div>
                  </div>

                  <div className="field">
                    <h2>{t("profilEdition.personalInfo.birthday")}</h2>
                    <div className="field__calendrier">
                      <Controller
                        name="birthday"
                        control={control}
                        render={({ field, fieldState }) => (
                          <ReactDatePicker
                            {...field}
                            id={field.name}
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode="select"
                            maxDate={new Date()}
                            placeholderText="20/09/2022"
                            // selected={
                            //   field.value == "" ? "" : convert(field.value)
                            // }
                            value={field.value}
                            dateFormat="dd/MM/yyyy"
                            onChange={(e) => {
                              field.onChange(convert(e));
                              setFiltreDate(true);
                            }}
                            className={
                              (classNames({
                                "p-invalid": fieldState.invalid,
                              }),
                              "small_item p-inputtext p-component datePickerInfoPerso")
                            }
                          />
                        )}
                      />
                      {filtreDate && (
                        <CgClose
                          onClick={() => {
                            setValue("birthday", null);
                            setFiltreDate(false);
                          }}
                        ></CgClose>
                      )}
                      {getFormErrorMessage("birthday")}
                    </div>
                  </div>
                  <div className="form_columns">
                    <div className="field">
                      <h2>{t("profilEdition.personalInfo.email")} *</h2>
                      <Controller
                        name="email"
                        control={control}
                        rules={{
                          required: t(
                            "profilEdition.personalInfo.emailValidationRequired"
                          ),
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: t(
                              "profilEdition.personalInfo.emailValidationPattern"
                            ),
                          },
                        }}
                        render={({ field, fieldState }) => (
                          <InputText
                            disabled={true}
                            id={field.name}
                            placeholder="hello123@gmail.com"
                            {...field}
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                          />
                        )}
                      />
                      {getFormErrorMessage("email")}
                      <div className="field checkbox">
                        <Controller
                          name="mailIsPublic"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              id={field.name}
                              checked={!field.value}
                              onChange={(e) => field.onChange(!e.checked)}
                            />
                          )}
                        />
                        <label htmlFor="mailIsPublic">
                          {t("profilEdition.personalInfo.privateMail")}
                        </label>
                        {getFormErrorMessage("mailIsPublic")}
                      </div>
                    </div>

                    <div className="field">
                      <h2 className={errors.telephone ? "p-error" : ""}>
                        {t("profilEdition.personalInfo.phone")} *
                      </h2>
                      <Controller
                        name="telephone"
                        control={control}
                        rules={{
                          validate: (value) =>
                            value
                              ? isPossiblePhoneNumber(value) ||
                                t(
                                  "profilEdition.personalInfo.phoneValidationPattern"
                                )
                              : t(
                                  "profilEdition.personalInfo.phoneValidationRequired"
                                ),
                        }}
                        render={({ field }) => (
                          <PhoneInput
                            id={field.name}
                            {...field}
                            onChange={(e) => field.onChange(e || "")}
                            placeholder="+33 6 00 00 00 00"
                            className="p-inputtext p-component"
                          />
                        )}
                      />

                      {getFormErrorMessage("telephone")}
                      <div className="field checkbox">
                        <Controller
                          name="telephoneIsPublic"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              id={field.name}
                              checked={!field.value}
                              onChange={(e) => field.onChange(!e.checked)}
                            />
                          )}
                        />
                        <label htmlFor="telephoneIsPublic">
                          {t("profilEdition.personalInfo.privatePhone")}
                        </label>
                        {getFormErrorMessage("telephoneIsPublic")}
                      </div>
                    </div>
                  </div>

                  <div className="field petit">
                    <h2>{t("profilEdition.personalInfo.country")}</h2>
                    <Controller
                      name="country"
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          id={field.name}
                          placeholder="France, Belgique..."
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                            setCurrentCountry(e.value);
                            setIsVisibleAdress(false);
                          }}
                          options={data}
                        />
                      )}
                    />
                    {getFormErrorMessage("country")}
                  </div>

                  <div className="form_columns">
                    <div className="field ville">
                      <h2>{t("profilEdition.personalInfo.address")}</h2>
                      <Controller
                        name="address"
                        control={control}
                        render={({ field, fieldState }) => (
                          <InputText
                            id={field.name}
                            placeholder="12 boulevard des coquelicots..."
                            {...field}
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                            onChange={(e) => {
                              field.onChange(e.target.value);
                              if (currentCountry === "France") {
                                if (e.target.value.length > 2) {
                                  setIsVisibleAdress(true);
                                  getAdress(e.target.value);
                                } else {
                                  setIsVisibleAdress(false);
                                }
                              }
                            }}
                          />
                        )}
                      />
                      {isVisibleAdress && (
                        <div className="listOfAdresses">
                          {listOfAdress.map((adress, key) => (
                            <div
                              className="searchedAdress"
                              key={key}
                              onClick={() => {
                                setValue("address", adress[0]);
                                setValue("city", adress[2]);
                                setIsVisibleAdress(false);
                              }}
                            >
                              <div className="adress">{adress[0]}</div>
                              <div className="department">{adress[1]}</div>
                            </div>
                          ))}
                        </div>
                      )}

                      {getFormErrorMessage("address")}
                      <div className="field checkbox">
                        <Controller
                          name="addressIsPublic"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              id={field.name}
                              checked={!field.value}
                              onChange={(e) => field.onChange(!e.checked)}
                            />
                          )}
                        />

                        <label htmlFor="addressIsPublic">
                          {t("profilEdition.personalInfo.privateAddress")}
                        </label>

                        {getFormErrorMessage("addressIsPublic")}
                      </div>
                    </div>
                    <div className="field ville">
                      <h2>{t("profilEdition.personalInfo.city")}</h2>
                      <Controller
                        name="city"
                        control={control}
                        render={({ field, fieldState }) => (
                          <InputText
                            id={field.name}
                            placeholder="Paris, Metz..."
                            {...field}
                            //   autoFocus
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                          />
                        )}
                      />
                      {getFormErrorMessage("city")}

                      {/* <div className="form_columns"> */}
                      <div className="field checkbox">
                        <Controller
                          name="cityAndCountryIsPublic"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              id={field.name}
                              checked={!field.value}
                              onChange={(e) => field.onChange(!e.checked)}
                            />
                          )}
                        />
                        <label htmlFor="cityAndCountryIsPublic">
                          {t("profilEdition.personalInfo.privateCity")}
                        </label>
                        {getFormErrorMessage("cityAndCountryIsPublic")}
                      </div>
                    </div>
                  </div>

                  {/* </div> */}
                  <div className="field parrain">
                    <h3>{t("profilEdition.personalInfo.license")}</h3>
                    <div className="permis_container">
                      <div className="permis">
                        <Controller
                          name="permisA"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              id={field.name}
                              checked={field.value}
                              value={false}
                              onChange={(e) => field.onChange(e.checked)}
                            />
                          )}
                        />
                        <label htmlFor="permisA">Permis A - moto</label>
                      </div>
                      <div className="permis">
                        <Controller
                          name="permisAM"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              id={field.name}
                              checked={field.value}
                              value={false}
                              onChange={(e) => field.onChange(e.checked)}
                            />
                          )}
                        />
                        <label htmlFor="permisAM">
                          Permis AM - cyclomoteur
                        </label>
                      </div>
                      <div className="permis">
                        <Controller
                          name="permisB"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              id={field.name}
                              checked={field.value}
                              value={false}
                              onChange={(e) => field.onChange(e.checked)}
                            />
                          )}
                        />
                        <label htmlFor="permisB">Permis B - auto</label>
                      </div>
                    </div>
                    <div className="permis_container">
                      <div className="permis">
                        <Controller
                          name="permisCD"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              id={field.name}
                              checked={field.value}
                              value={false}
                              onChange={(e) => field.onChange(e.checked)}
                            />
                          )}
                        />
                        <label htmlFor="permisCD">
                          Permis C et/ou D - professionnels
                        </label>
                      </div>
                      <div className="permis">
                        <Controller
                          name="permisE"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              id={field.name}
                              value={false}
                              checked={field.value}
                              onChange={(e) => field.onChange(e.checked)}
                            />
                          )}
                        />
                        <label htmlFor="permisE">Permis E - remorque</label>
                      </div>
                    </div>
                  </div>
                  {props.user.userRoles.some(
                    (role) => role.roleName == "ROLE_ALUMNI"
                  ) ? (
                    <div className="form_columns">
                      <div className="field parrain">
                        <h3>
                          Parrainer un étudiant <br />
                        </h3>

                        <Controller
                          name="mentorAccept"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              id={field.name}
                              checked={field.value}
                              onChange={(e) => field.onChange(e.checked)}
                            />
                          )}
                        />
                        <label htmlFor="mentorAccept">Parrain/Marraine</label>
                        <label className="label_parrain">
                          (vous acceptez d&apos;être contacté par des étudiants
                          à la recherche de conseils)
                        </label>
                        {getFormErrorMessage("mentorAccept")}
                      </div>
                      <div className="field parrain">
                        <h3>{t("profilEdition.personalInfo.founder")}</h3>
                        <Controller
                          name="companyCreator"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              id={field.name}
                              checked={field.value}
                              onChange={(e) => field.onChange(e.checked)}
                            />
                          )}
                        />
                        <label htmlFor="companyCreator">
                          {t("profilEdition.personalInfo.amFounder")}
                        </label>
                        {getFormErrorMessage("companyCreator")}
                      </div>
                    </div>
                  ) : (
                    <div className="field parrain">
                      <h3>{t("profilEdition.personalInfo.founder")}</h3>
                      <Controller
                        name="companyCreator"
                        control={control}
                        render={({ field }) => (
                          <Checkbox
                            id={field.name}
                            checked={field.value}
                            onChange={(e) => field.onChange(e.checked)}
                          />
                        )}
                      />
                      <label htmlFor="companyCreator">
                        {t("profilEdition.personalInfo.amFounder")}{" "}
                      </label>
                      {getFormErrorMessage("companyCreator")}
                    </div>
                  )}

                  <br />

                  <div className="form_boutons btnInfoPersoValider">
                    <BtnBleuLeft btnTexte={t("profilEdition.validate")}>
                      <FaCheck />
                    </BtnBleuLeft>
                  </div>
                </form>
              ) : (
                <>
                  <ProfilBarShowApercu
                    currentUser={formData}
                    image={(() => {
                      if (phototheque && imageId) {
                        return usersState?.imageStocks?.find(
                          (image) => image.id === imageId
                        )?.resourceUrl;
                      }
                      return image;
                    })()}
                  />
                  <div className="form_boutons">
                    {isProcessing ? (
                      <Loader />
                    ) : (
                      <BtnBleuLeft
                        btnTexte="Valider "
                        btnAction={() => onUpdate()}
                      >
                        <FaCheck />
                      </BtnBleuLeft>
                    )}
                  </div>
                </>
              )}
            </div>
          ) : (
            <Loader></Loader>
          )}
        </div>
      )}
    </div>
  );
};

InformationsPerso.propTypes = {
  auth: PropTypes.object,
  items: PropTypes.array,
  activeIndex: PropTypes.number,
  handleUpdateAuth: PropTypes.func,
  handleUpdateCompletionLevel: PropTypes.func,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateAuth: (value) => {
    dispatch(updateAuth(value));
  },
  handleUpdateCompletionLevel: (value) => {
    dispatch(updateCompletionLevel(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InformationsPerso);
