import React, { useState, useEffect, useRef, useCallback } from "react";
import "./GroupeCreation.scss";
import PropTypes from "prop-types";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { FaCog } from "react-icons/fa";
import { connect } from "react-redux";
import { updateMenu } from "Redux/Actions/menuActions";
import axios from "axios";
import { isNotEmptyArray } from "Services/functions";
import { Controller, useForm } from "react-hook-form";
import Loader from "Components/Loader/loader";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import ChargementImage from "Components/ChargementImage/ChargementImage";
import { updateGroups } from "Redux/Actions/groupsActions";
import BtnBlanc from "Components/Boutons/BtnBlancLeft/btn-blanc-left";
import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import Modal from "Components/Modal/Modal";
import ModalCharteGroupes from "Components/Modal/ModalCharteGroupes/ModalCharteGroupes";
import ReactQuill from "react-quill";
import QuillOptions from "Components/QuillOptions/QuillOptions";
import DOMPurify from "dompurify";
import SearchPeople from "Components/SearchPeople/SearchPeople";
import { CgClose } from "react-icons/cg";
import { fetchGet, useFetchGetPagination } from "Services/api";
import { useTranslation } from "react-i18next";
import { debounce } from "../../../Services/debounce";
import { PublishState, publishStateOptions } from "Services/publishStates.d";

/**
 * Permet d'afficher le formulaire de création de groupe
 */
// TO DO : envoyer les utilisateurs de membres à l'api au moment de la soumission
const GroupeCreation = (props) => {
  const [visibleModalCharte, setvisibleModalCharte] = useState(false);
  // ------- VARIABLES DES GROUPES
  // Contiendra les données du formulaire après soumission de celui-ci
  const [formData, setFormData] = useState("");
  // composante de formation associée au groupe
  const composanteData = props.secondaryTables.educationComposanteData;
  // domaine de formation associé au groupe
  const formationData = props.secondaryTables.domainsData;
  // spécialité de formation associée au groupe
  const specialiteData = props.groups.specialitesFormation;

  const [members, setMembers] = useState([]);

  const [submitted, setSubmitted] = useState(false);

  const updateState = async () => {};

  const { t } = useTranslation("common");

  useEffect(() => {
    if (props.states.statesData && props.states.statesData.length > 0) {
      if (props.auth.isAdmin) {
        props.handleGroups({
          group_status_envoi: props.states.statesData.filter(
            (status) => status.statusLabel == "Publié"
          )[0],
        });
      } else {
        props.handleGroups({
          group_status_envoi: props.states.statesData.filter(
            (status) => status.statusLabel == "En attente"
          )[0],
        });
      }
    }
  }, [props.states.statesData]);

  // Constantes comprenant les données par défaut du formulaire de création d'une actu
  const defaultValues = {
    label: "",
    description: "",
    public: false,
    institutional: false,
    groupStatus: null,
    groupCategory: null,
    educationDomain: null,
    educationComposante: null,
    educationSpeciality: null,
    createdBy: true,
    publishOn: PublishState.PUBLISH_ALUMNI,
    image: "",
  };

  // ------- VARIABLES GESTION IMAGES ----------
  // La photo vient-elle de la photothèque ?
  const [phototheque, setPhototheque] = useState(true);
  // Contiendra l'url de l'image choisie (upload ou photothèque)
  const [imageFile, setImageFile] = useState("");
  // ID de l'image choisie (phototheque)
  const [imageId, setImageId] = useState("");

  // Contiendra le fichier upload par l'utilisateur
  const [image, setImage] = useState("");

  // ------- VARIABLES GESTION FORMULAIRE ----------
  // catégorie de l'actu choisie
  const [categories, setCategories] = useState([]);
  // Défini si l'actualité a bien été publiée / soumise ou non
  // const [isSubmitted, setSubmitted] = useState(false);

  // ------- TOASTS ----------
  // Toast de succès d'upload d'image
  const uploadToast = useRef(null);
  // Toast de suppression de l'image upload
  const cancelToast = useRef(null);

  // ------- GESTION AJOUT MEMBRES ----------
  const [filteredMembersURL, setFilteredMembersURL] = useState(null);
  const filteredMembersQuery = useFetchGetPagination(
    filteredMembersURL,
    props.auth.token
  );
  const [filteredMembers, setFilteredMembers] = useState([]);

  useEffect(() => {
    // On enlève l'utilisateur connecté de la liste des membres
    let newFilteredMembers = filteredMembers.filter(
      (member) => member.id != props.auth.userConnected.id
    );
    if (members.length === 0) setMembers([...newFilteredMembers]);
  }, [filteredMembers]);

  useEffect(() => {
    if (filteredMembersQuery.loaded && filteredMembersQuery.data) {
      setFilteredMembers(filteredMembersQuery.data);
    }
  }, [filteredMembersQuery]);

  // Une fois les catégories chargées, on les stocke dans un tableau :
  useEffect(async () => {
    /// Récupérer les catégories, et les filtrer par ordre alphabétique
    const categoriesList = isNotEmptyArray(props.groups.categories)
      ? props.groups.categories
      : await fetchGet("/group_categories", props.auth.token);
    setCategories([]);
    let tempArray = [];
    categoriesList.forEach((category) => tempArray.push(category));
    tempArray = tempArray.sort((a, b) => a.label.localeCompare(b.label));
    setCategories(tempArray);
  }, [props.groups.categories]);

  const [specialites, setSpecialites] = useState(null);

  useEffect(() => {
    if (!specialiteData) return;

    let tempArray = [];
    specialiteData.forEach((specialite) => {
      let diplomes = specialite?.diplomes;
      tempArray.push({
        ...specialite,
        customLabel: `${specialite?.label}${
          isNotEmptyArray(diplomes) ? " (" + diplomes[0]?.codeDiplome + ")" : ""
        }`,
      });
    });
    tempArray = tempArray?.sort((a, b) => a?.label?.localeCompare(b?.label));
    setSpecialites(tempArray);
  }, [specialiteData]);

  // Une fois le formulaire soumis, on lance le processus d'envoi de données
  useEffect(() => {
    if (formData !== "") {
      if (props.auth.userConnected.charteGroupSigned) {
        postForm();
      } else {
        setvisibleModalCharte(true);
      }
    }
  }, [formData]);

  /**
   * Fonction permettant de convertir les données du formulaire
   * en format {@link FormData} et des les envoyer au serveur
   * @param {Object} data données du formulaire
   */
  const sendRequest = (data) => {
    // Variable qui stockera la réponse du serveur
    // let response = null;
    // On crée un FormData qui sera envoyé au serveur
    var dataForm = new FormData();
    // On ajoute les données du formulaire au formData
    for (var key in data) dataForm.append(key, data[key]);

    // On envoie le formData au serveur
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL_API}${"/groups/request/validation"}`,
        dataForm,
        {
          headers: props.auth.token
            ? {
                accept: "application/json",
                "Content-Type": "multipart/form-data",

                Authorization: `Bearer ${props.auth.token}`,
              }
            : {
                accept: "application/json",
                "Content-Type": "multipart/form-data",
              },
        }
      )
      .then(() => {
        reset();
        setImage(null);
        setImageFile(null);
        setPhototheque(true);
        setSubmitted(true);
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response?.statusText === "Unprocessable Entity" ||
            error.response?.status == 422
          )
            cancelToast.current.show({
              severity: "error",
              summary: "Erreur : ",
              detail:
                error?.response?.data?.detail ||
                "L'image doit être au format paysage et ne doit pas dépasser 1Mo",
              life: 10000,
            });
          else
            cancelToast.current.show({
              severity: "error",
              summary: "Erreur : ",
              detail:
                error?.response?.data?.detail || "Une erreur est survenue.",
              life: 10000,
            });
        } else
          cancelToast.current.show({
            severity: "error",
            summary: "Erreur : ",
            detail: "Une erreur est survenue.",
            life: 10000,
          });
      });
  };

  const sendMembers = (data) => {
    if (data) return data;
    // Si groupe institutionnel, on ajoute les membres en tant que statut "ACTIF"
    // Sinon on ajoute les membres en tant que statut "INVITE"
  };

  /**
   * Remplissage de la key image selon différentes conditions
   * @param {Object} data données pour la requêtes
   * @returns {Object}
   */
  const fillImage = (data) => {
    // Si une image a été téléchargée, on met son url local dans la key imageFile
    if (imageFile && !phototheque) {
      data.imageFile = imageFile;
      delete data.imageStockId;
    }
    // Sinon, on met l'url de l'image choisie de la photothèque
    else if (phototheque) {
      data.imageStockId = imageId;
      delete data.imageFile;
      delete data.image;
    }
    return data;
  };

  /**
   * Remplissage de la key category selon différentes conditions
   * @param {Object} data données pour la requêtes
   * @returns {Object}
   */
  const fillCategory = (data) => {
    // Si catégory est vide on met la catégorie par défaut 'Autre'
    if (!data.groupCategory) data.groupCategory = "Autre";

    // Si la key id existe, pas de besoin de chercher l'id de la catégorie
    if (data.groupCategory.id)
      data.groupCategory = "/api/group_categories/" + data.groupCategory.id;
    /* 
    Sinon, on cherche si la catégorie existe déjà :
    // Si oui : on met l'id de la catégorie
     Si non : on spécifie qu'elle doit être créée
     */ else {
      const category = categories.find(
        (category) => category.label === data.groupCategory
      );
      if (category) data.groupCategory = "/api/group_categories/" + category.id;
      else data.groupCategory = JSON.stringify({ label: data.groupCategory });
    }
    return data;
  };

  /**
   * Remplissage des keys educationComposante, domain et educationSpeciality
   * selon différentes conditions
   * @param {Object} data données pour la requêtes
   * @returns {Object} - données pour la requêtes
   */
  const fillAssociations = (data) => {
    // Si le champ spécialité est renseigné, on met l'id de la spécialité
    if (data.educationSpeciality && data.educationSpeciality.id)
      data.educationSpeciality =
        "/api/education_specialities/" + data.educationSpeciality.id;
    // Si le champ domaine est renseigné, on met l'id du domaine
    if (data.educationDomain && data.educationDomain.id)
      data.educationDomain = "/api/domains/" + data.educationDomain.id;
    // Si le champ composante est renseigné, on met l'id de la composante
    if (data.educationComposante && data.educationComposante.id)
      data.educationComposante =
        "/api/education_composantes/" + data.educationComposante.id;
    return data;
  };

  const cleanEmptyKeys = (data) => {
    // Suppression des keys qui ne sont pas remplies sauf les clés à false
    for (var key in data)
      if (data[key] === "" || (!data[key] && data[key] !== false))
        delete data[key];
    return data;
  };

  const postForm = () => {
    // Association du groupe à l'utilisateur
    let createdBy = "/api/users/" + props.auth.userConnected.id;

    // Création d'un object qui va contenir toutes les clés du formulaire soumis
    var data = new Object();
    var dataMembers = new Object();
    data.description = DOMPurify.sanitize(data.description);
    data.createdBy = createdBy;
    data = { ...formData };
    data = fillImage(data);
    data = fillAssociations(data);
    data = fillCategory(data);
    data = cleanEmptyKeys(data);
    data = {
      ...data,
      groupMembers: JSON.stringify(
        members.map((member) => `/api/users/${member.id}`)
      ),
    };
    sendRequest(data);
    sendMembers(dataMembers);
  };

  // variables du formulaire
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
    trigger,
    watch,
    // setValue,
  } = useForm({ defaultValues });

  /**
   * Fonction permettant d'assigner les données du formulaire
   * à une variable
   * @param {Object} data données du formulaire
   */
  const onSubmit = async (data) => {
    let check = await trigger();
    if (check) setFormData(data);
  };

  /**
   * Fonction permettant de filtrer les membres en fonction des paramètres
   * sélectionnés
   * @param {{id: Number}} composante - composante sélectionnée
   * @param {{id: Number}}  speciality- spécialité sélectionnée
   * @param {{id: Number}}  year - année de promotion sélectionnée
   * @param {{id: Number}}  domain - domaine sélectionné
   * @returns {{composante: {id: {number}}, speciality: {id: {number}}, year:{id: {number}}, domain: {id: {number}}}} - tableau des membres filtrés
   */
  const makeFilteredMembersURL = (composante, speciality, year, domain) => {
    let url = process.env.REACT_APP_BASE_URL_API;
    url += "/users";
    url = new URL(url);
    if (composante && composante.id)
      url.searchParams.append("composante", composante.id);
    if (speciality && speciality.id)
      url.searchParams.append("speciality", speciality.id);
    if (year) url.searchParams.append("promotionYear", year);
    if (domain && domain.id) url.searchParams.append("domain", domain.id);
    setFilteredMembersURL(url);
  };

  // UseEffect pour filtrer les membres en fonction des champs suivants :
  // composante, spécialité, année de promotion, domaine
  useEffect(() => {
    const composante = getValues("educationComposante");
    const speciality = getValues("educationSpeciality");
    const domain = getValues("educationDomain");
    if (composante?.id || speciality?.id || domain?.id)
      makeFilteredMembersURL(composante, speciality, domain);
    else setMembers([]);
  }, [
    watch("educationDomain"),
    watch("educationComposante"),
    watch("educationSpeciality"),
  ]);

  /**
   * Afffichage d'une erreur si nécessaire dans le formulaire
   * @param {String} name nom du champ
   * @returns {FieldError, JSX.Element}
   */
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  /**
   * Fonction permettant de combiner le code de la composante et son nom, si ce n'est pas déjà fait
   * puis de les trier par ordre alphabétique
   * @param {[{label: String, code_composante: String}]} composantes - liste des composantes
   * @returns {[{label: String, code_composante: String, displayLabel: String}]} - liste des composantes formatées et triées
   */
  const formatComposantes = (composantes) => {
    composantes
      .map((composante) => {
        if (!composante.label.match("\\d+[':']"))
          composante.displayLabel =
            composante["code_composante"] + ": " + composante.label;
        else composante.displayLabel = composante.label;
        return composante;
      })
      .sort((a, b) => a.displayLabel.localeCompare(b.displayLabel));
    return composantes;
  };

  const debounceGroupName = useCallback(debounce(validateGroupName, 500), []);

  /**
   * Fonction permettant de vérifier si le nom de groupe est déjà utilisé
   * ou non
   * @param value - nom du groupe
   * @returns {Promise<string|boolean>} - message d'erreur ou true
   */
  async function validateGroupName(value) {
    let exists = await fetchGet(`/groups?label=${value}`, props.auth.token);

    return exists && exists.length > 0 && value === exists?.at(0)?.label
      ? t("createGroup.titleValidationAlreadyExists")
      : true;
  }

  return (
    <div>
      <Toast ref={uploadToast} />
      <Toast ref={cancelToast} />
      <div className="groupe_container">
        <div className="groupe_container__title">
          {props.auth.isAdmin ? (
            <h1 className="h1_trait_dessus">{t("createGroup.createHeader")}</h1>
          ) : (
            <h1 className="h1_trait_dessus">
              {t("createGroup.proposeHeader")}
            </h1>
          )}

          <div className="groupe_container__title__filtres gestion">
            <BtnBlanc
              btnTexte={t("groupManagement.header")}
              btnAction={() => props.setCreation(!props.creation)}
            >
              <FaCog />
            </BtnBlanc>
          </div>
        </div>

        {/* <p> Sous-titres à définir</p> */}
        <div className="creation_groupe_container">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="question_columns large">
              <div className="question">
                <h2
                  htmlFor="label"
                  className={classNames({ "p-error": errors.label })}
                >
                  {t("createGroup.title")} *
                </h2>
                <Controller
                  name="label"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: t("createGroup.titleValidationRequired"),
                    },
                    maxLength: {
                      message: t("createGroup.titleValidationMax"),
                      value: 50,
                    },
                    validate: debounceGroupName,
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      value={field.value}
                      id={field.name}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        trigger("label");
                      }}
                      placeholder={t("createGroup.titlePlaceholder")}
                      className={
                        (classNames({
                          "p-invalid": fieldState.invalid,
                        }),
                        "input_groupe")
                      }
                    />
                  )}
                />
                {getFormErrorMessage("label")}
              </div>
              <div className="question">
                <h2
                  htmlFor="groupCategory"
                  className={classNames({ "p-error": errors.groupCategory })}
                >
                  {t("createGroup.category")}
                </h2>
                {categories?.length >= 0 ? (
                  <Controller
                    name="groupCategory"
                    control={control}
                    render={({ field, fieldState }) => (
                      <Dropdown
                        optionLabel="label"
                        editable
                        id={field.name}
                        showClear
                        value={field.value}
                        options={categories}
                        placeholder={t("createGroup.categoryPlaceholder")}
                        onChange={(e) => field.onChange(e.value)}
                        className={
                          (classNames({
                            "p-invalid": fieldState.invalid,
                          }),
                          "input_groupe")
                        }
                      />
                    )}
                  />
                ) : (
                  <Loader />
                )}
                {getFormErrorMessage("groupCategory")}
              </div>
            </div>

            <div className="question column">
              <h2>{t("createGroup.association")}</h2>
            </div>
            <div className="question_columns">
              <div className="question">
                <label
                  htmlFor="educationComposante"
                  className={classNames({
                    "p-error": errors.educationComposante,
                  })}
                >
                  {t("createGroup.associationComponent")}
                </label>
                <Controller
                  name="educationComposante"
                  control={control}
                  render={({ field, fieldState }) => (
                    <Dropdown
                      optionLabel="displayLabel"
                      id={field.name}
                      value={field.value}
                      filter
                      showClear
                      options={formatComposantes(composanteData)}
                      placeholder={t(
                        "createGroup.associationComponentPlaceholder"
                      )}
                      onChange={(e) => field.onChange(e.value)}
                      className={
                        (classNames({
                          "p-invalid": fieldState.invalid,
                        }),
                        "input_groupe groupes-dropdown")
                      }
                    />
                  )}
                />
              </div>
              <div className="question">
                <label
                  htmlFor="educationDomain"
                  className={classNames({
                    "p-error": errors.educationDomain,
                  })}
                >
                  {t("createGroup.associationDomain")}
                </label>
                <Controller
                  name="educationDomain"
                  control={control}
                  render={({ field, fieldState }) => (
                    <Dropdown
                      optionLabel="label"
                      id={field.name}
                      showClear
                      filter
                      value={field.value}
                      options={formationData}
                      placeholder={t(
                        "createGroup.associationDomainPlaceholder"
                      )}
                      onChange={(e) => field.onChange(e.value)}
                      className={
                        (classNames({
                          "p-invalid": fieldState.invalid,
                        }),
                        "input_groupe groupes-dropdown")
                      }
                    />
                  )}
                />
              </div>
              <div className="question">
                <label
                  htmlFor="educationSpeciality"
                  className={classNames({
                    "p-error": errors.educationSpeciality,
                  })}
                >
                  {t("createGroup.associationSpecialty")}
                </label>
                <Controller
                  name="educationSpeciality"
                  control={control}
                  render={({ field, fieldState }) => (
                    <Dropdown
                      optionLabel="customLabel"
                      id={field.name}
                      value={field.value}
                      showClear
                      filter
                      options={specialites}
                      placeholder={t(
                        "createGroup.associationSpecialtyPlaceholder"
                      )}
                      onChange={(e) => field.onChange(e.value)}
                      className={
                        (classNames({
                          "p-invalid": fieldState.invalid,
                        }),
                        "input_groupe groupes-dropdown")
                      }
                    />
                  )}
                />
              </div>
            </div>
            <div className="question search">
              <h2>{t("createGroup.addMembers")}</h2>

              <div className="multiselect-demo">
                <div className="multiselect-demo_list">
                  {members.map((member, index) => (
                    <span key={index} className="member_fullname">
                      <span className="member_firstname">
                        {member.firstname}
                      </span>
                      <span className="member_surname">{member.surname}</span>
                      <CgClose
                        onClick={() => {
                          setMembers(
                            members.filter((item) => item.id != member.id)
                          );
                        }}
                      ></CgClose>
                    </span>
                  ))}
                </div>
                <SearchPeople
                  userList={members}
                  setUserList={setMembers}
                ></SearchPeople>
              </div>
            </div>
            <ChargementImage
              image={image}
              setImage={setImage}
              imageFile={imageFile}
              setImageFile={setImageFile}
              setImageId={setImageId}
              uploadToast={uploadToast}
              cancelToast={cancelToast}
              phototheque={phototheque}
              setPhototheque={setPhototheque}
              path="groups"
              long
            />
            <div className="description">
              <h2
                htmlFor="description"
                className={classNames({ "p-error": errors.description })}
              >
                {t("createGroup.description")}
              </h2>
              <Controller
                name="description"
                control={control}
                rules={{
                  required: {
                    message: t("createGroup.descriptionValidationRequired"),
                    value: true,
                  },
                }}
                render={({ field }) => (
                  <ReactQuill
                    id={field.name}
                    {...field}
                    theme="snow"
                    modules={QuillOptions.modules}
                    formats={QuillOptions.formats}
                    rows={5}
                  />
                )}
              />
              {getFormErrorMessage("description")}
            </div>

            {props.auth.isAdmin && (
              <div className="description">
                <h2>{t("createGroup.publishState")} *</h2>
                <Controller
                  name="publishOn"
                  control={control}
                  render={({ field }) => (
                    <Dropdown
                      id="publishOn"
                      value={field.value}
                      options={publishStateOptions}
                      onChange={(e) => field.onChange(e.value)}
                      optionLabel="label"
                      placeholder="Choisir la visibilité"
                    />
                  )}
                />
              </div>
            )}

            <div className="groupe_prive">
              <div className="question checkbox">
                <Controller
                  name="public"
                  control={control}
                  render={({ field, fieldState }) => (
                    <Checkbox
                      id={field.name}
                      checked={!field.value}
                      onChange={(e) => field.onChange(!e.checked)}
                      className={
                        (classNames({
                          "p-invalid": fieldState.invalid,
                        }),
                        "input_groupe checkbox_groupe")
                      }
                    />
                  )}
                />
                <label
                  htmlFor="public"
                  className={classNames({ "p-error": errors.public })}
                >
                  <i className="pi pi-lock"></i> {t("createGroup.private")}
                </label>
                {getFormErrorMessage("public")}
              </div>
              <div className="question checkbox">
                <Controller
                  name="public"
                  control={control}
                  render={({ field, fieldState }) => (
                    <Checkbox
                      id={field.name}
                      checked={field.value}
                      onChange={(e) => field.onChange(e.checked)}
                      className={
                        (classNames({
                          "p-invalid": fieldState.invalid,
                        }),
                        "input_groupe checkbox_groupe")
                      }
                    />
                  )}
                />
                <label
                  htmlFor="public"
                  className={classNames({ "p-error": errors.public })}
                >
                  <i className="pi pi-lock-open"></i> {t("createGroup.public")}
                </label>
                {getFormErrorMessage("public")}
              </div>
            </div>
            {props.auth.userConnected.userRoles.some(
              (role) =>
                role.roleName == "ROLE_ADMIN_DU_PORTAIL" ||
                role.roleName == "ROLE_PERSONNEL_UNIVERSITE"
            ) && (
              <div className="groupe_prive">
                <div className="question checkbox">
                  <Controller
                    name="institutional"
                    control={control}
                    render={({ field, fieldState }) => (
                      <Checkbox
                        id={field.name}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.checked)}
                        className={
                          (classNames({
                            "p-invalid": fieldState.invalid,
                          }),
                          "input_groupe checkbox_groupe")
                        }
                      />
                    )}
                  />
                  <label
                    htmlFor="institutional"
                    className={classNames({ "p-error": errors.institutional })}
                  >
                    <i className="pi pi-lock"></i>{" "}
                    {t("createGroup.institutional")}
                  </label>
                  {getFormErrorMessage("institutional")}
                </div>
              </div>
            )}

            <div className="question bouton">
              {submitted && (
                <Modal
                  visible={submitted}
                  setVisible={setSubmitted}
                  className={"modal modal_groupe-creation"}
                  header={
                    props.auth.isAdmin
                      ? t("createGroup.createSuccess")
                      : t("createGroup.submitSuccess")
                  }
                >
                  <i className="pi pi-send"></i>
                  <div className="description">
                    <p>
                      <center>
                        {props.auth.isAdmin
                          ? t("createGroup.createSuccess")
                          : t("createGroup.submissionSent")}
                      </center>
                    </p>
                    {!props.auth.isAdmin ? (
                      <>
                        <p>
                          <center>{t("createGroup.awaitValidation")}</center>
                        </p>
                      </>
                    ) : (
                      <>
                        <p>
                          <center>{t("createGroup.createDetail")}</center>
                        </p>
                        <p>
                          <center>{t("createGroup.createDetailBis")}</center>
                        </p>
                      </>
                    )}
                  </div>
                  <div className="modals-buttons">
                    <BtnBleu
                      btnTexte={t("createGroup.submitAgain")}
                      btnAction={(e) => {
                        e.preventDefault();
                        setSubmitted(false);
                      }}
                    />
                    <BtnBlanc
                      btnTexte={t("createGroup.returnToGroups")}
                      btnAction={(e) => {
                        e.preventDefault();
                        props.setCreation(!props.creation);
                        updateState();
                        setSubmitted(false);
                      }}
                    />
                  </div>
                </Modal>
              )}
              {cancelToast.current && (
                <button
                  type="button"
                  className="btn-bleu"
                  onClick={async () =>
                    (await !trigger())
                      ? cancelToast.current.show({
                          severity: "error",
                          summary: "Validation",
                          detail: t("createGroup.invalidForm"),
                          sticky: true,
                        })
                      : onSubmit(getValues())
                  }
                >
                  {props.auth.isAdmin
                    ? t("createGroup.submitCreate")
                    : t("createGroup.submitPropose")}
                </button>
              )}
            </div>
          </form>
          {visibleModalCharte && (
            <ModalCharteGroupes
              visibleModalCharte={visibleModalCharte}
              setvisibleModalCharte={setvisibleModalCharte}
              btnAccept={postForm}
              btnRefuse={() => {
                cancelToast.current.show({
                  severity: "error",
                  summary: "Charte",
                  detail: t("createGroup.declineCharter"),
                  sticky: true,
                });
              }}
            ></ModalCharteGroupes>
          )}
        </div>
      </div>
    </div>
  );
};
GroupeCreation.propTypes = {
  name: PropTypes.string,
  chapeau: PropTypes.string,
  bouton: PropTypes.string,
  activeIndex: PropTypes.number,
  setActiveIndex: PropTypes.func,
  items: PropTypes.object,
  handleMenu: PropTypes.func,
  creation: PropTypes.bool,
  setCreation: PropTypes.func,
  auth: PropTypes.object,
  groups: PropTypes.object,
  handleGroups: PropTypes.func,
  users: PropTypes.object,
  secondaryTables: PropTypes.object,
  states: PropTypes.object,
};
const mapStateToProps = (state) => ({
  items: state.items,
  auth: state.auth,
  groups: state.groups,
  users: state.users,
  states: state.states,
  secondaryTables: state.secondaryTables,
});
const mapDispatchToProps = (dispatch) => ({
  handleMenu: (value) => {
    dispatch(updateMenu(value));
  },
  handleGroups: (value) => {
    dispatch(updateGroups(value));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(GroupeCreation);
