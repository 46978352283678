import React from "react";
import PropTypes from "prop-types";
import Loader from "Components/Loader/loader";

/**
 * DataHandler est un composant qui permet de gérer l'affichage des données
 * provenant d'une API, en fonction de leur état de chargement et de leur contenu.
 *
 * - Si les données sont en cours de chargement, un loader est affiché.
 * - Si les données sont chargées et qu'elles sont vides, un message d'information est affiché.
 * - Si les données sont chargées et qu'elles ne sont pas vides, on affiche les enfants du composant.
 * - Si une erreur est survenue, un message d'erreur est affiché.
 * @param {object|array<any, any>|null} data - Les données à afficher (si elles existent).
 * @param {boolean} loaded
 * @param {any} error
 * @param {null|JSX.Element} children
 * @param {string} emptyMessage
 * @param {string }errorMessage
 * @returns {*|JSX.Element}
 */
export default function DataHandler({
  data,
  loaded,
  error,
  children,
  emptyMessage,
  errorMessage,
}) {
  const displayError = () => {
    return <p>{errorMessage}</p>;
  };

  try {
    if (!loaded) {
      return <Loader />;
    }

    if (error) {
      return <p>{errorMessage}</p>;
    }

    if (
      loaded &&
      (data === null || (Array.isArray(data) && data.length === 0))
    ) {
      return <span>{emptyMessage}</span>;
    }

    return data ? children : null;
  } catch (e) {
    return displayError();
  }
}

DataHandler.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  loaded: PropTypes.bool,
  error: PropTypes.any,
  children: PropTypes.node,
  emptyMessage: PropTypes.string,
  errorMessage: PropTypes.string,
};

DataHandler.defaultProps = {
  data: null,
  loaded: false,
  error: null,
  children: null,
  emptyMessage: "Aucun résultat n'a été trouvé.",
  errorMessage: "Une erreur est survenue.",
};
