import Management from "Components/Management/Management";
import TempPagination from "Components/Pagination/TempPagination";
import { updateMenu } from "Redux/Actions/menuActions";
import { updateNews } from "Redux/Actions/newsActions";
import { updatePagination } from "Redux/Actions/paginationActions";
import { useFetchGetPagination } from "Services/api";
import {
  filterByTransmitter,
  formatDateFrenchLocale,
} from "Services/functions";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import ActualiteManagerCard from "./ActualiteCard/ActualiteCard";
import "./ActualitesManagement.scss";
import { ME, newsMgmtButtons } from "../../Management/ManagementButtons";
import DataHandler from "Components/DataHandler/DataHandler";

/**
 * Permet d'afficher la page de gestion des actus
 * Accessible pour les administrateurs, alumni et personnels
 */
const ActualitesManagement = (props) => {
  const { t } = useTranslation("common");

  // *****************************************************************
  // ****************** UseStates / UseRef *********************
  // *****************************************************************

  const [url, setUrl] = useState(null);
  const [states, setStates] = useState([]);
  const [btnList, setBtnList] = useState(
    newsMgmtButtons.map((x) => {
      return { ...x };
    })
  );
  //? On ne fait pas de requête si aucun état n'est sélectionné
  //? Des états par défaut sont appliqués dans un useEffect
  //! Permet d'éviter de faire deux requêtes au chargement de la page
  const newsQuery = useFetchGetPagination(
    url,
    props.auth.token,
    props.handleUpdateNews,
    true
  );

  const actualitesContainerRef = useRef(null);
  const newsMngRef = useRef(null);

  useEffect(() => {
    props.handleUpdateNews({ currentTransmitter: [ME] });
    return () => {
      props.handleUpdateNews({ dataFromPagination: null });
      props.handleUpdatePagination({ currentPage: 1 });
      props.handleUpdatePagination({ paginationBasicFirst: 0 });
      props.handleUpdatePagination({ paginationBasicRows: 12 });
    };
  }, []);

  useEffect(() => {
    //? remise à zéro si les états sont chargés
    //? et que l'url ne contient pas de state
    if (states && states?.length > 0 && !url?.href?.includes("state")) {
      if (newsMngRef.current !== null) {
        window.scroll({
          top: newsMngRef.current.offsetTop,
          behavior: "smooth",
        });
      }
    }
  }, [states]);

  useEffect(() => {
    /// Récupérer les statuts, et les filtrer par ordre alphabétique
    if (props.news.etats && props.news.etats.length > 0) {
      setStates([]);
      let tempArray = props.news.etats.map((state) => {
        return { ...state };
      });
      tempArray = tempArray.sort((a, b) => a.name.localeCompare(b.name));
      tempArray.forEach((state) => {
        if (state.name.lastIndexOf("é") === state.name.length - 1) {
          state.name = state.name + "e";
        }
      });
      setStates(tempArray);
    }
  }, [props.news.etats]);

  /**
   * Fonction qui permet de gérer les boutons de gauche,
   * et notamment de changer l'url en fonction du transmetteur
   * - Entreprise : on enlève le statut "Publié"
   * - Autre : on rajoute le statut "Publié" s'il n'est pas déjà présent
   * @param {Number} index
   * @returns {void}
   */
  const onBtnClick = (index) => {
    newsQuery.setPage(1);

    let newUrl = new URL(url?.toString());

    newUrl = filterByTransmitter(
      "news",
      newUrl,
      btnList[index].transmitter,
      props.auth.userConnected.id,
      states.find((state) => state.name?.includes("Publié"))?.id
    );
    setUrl(newUrl.toString());
    props.handleUpdatePagination({ paginationBasicFirst: 0 });

    props.handleUpdateNews({
      currentTransmitter: btnList[index].transmitter,
      dataFromPagination: null,
    });
    let i = 0;
    setBtnList(
      btnList.map((btn) => {
        btn.isActive = i === index;
        i++;
        return btn;
      })
    );
  };

  return (
    <>
      <div className="gestion_evenements" ref={newsMngRef}>
        <div className="gestion_evenements__title">
          <h1 className="h1_trait_dessus">
            {props.auth.isAdmin
              ? t("actusApresConnexion.submenuGererAdmin")
              : t("actusApresConnexion.submenuGerer")}
          </h1>
          <Management
            states={states}
            btnEntreprise={btnList[2]}
            actualPage={"actu"}
            currentTransmitter={
              btnList.filter((btn) => btn.isActive === true)[0].transmitter
            }
            updateFunction={props.handleUpdateNews}
            setUrl={setUrl}
          ></Management>
        </div>
        <div className="gestion_evenements__body">
          <div className="gestion_evenements__body__left">
            {props.auth.isAdmin &&
              btnList.map((btnStatus, index) => {
                return (
                  <button
                    className={
                      btnStatus.isActive
                        ? "btn-bleu " + "item" + index
                        : "btn-blanc " + "item" + index
                    }
                    onClick={() => onBtnClick(index)}
                    key={index}
                  >
                    {btnList[index].text}
                  </button>
                );
              })}
          </div>
          <div
            className="gestion_evenements__body__right"
            ref={actualitesContainerRef}
            key={props.news.dataFromPagination}
          >
            <DataHandler
              data={newsQuery.data || null}
              error={newsQuery.error || null}
              loaded={newsQuery.loaded || false}
              emptyMessage={"Aucune actualité ne correspond à votre recherche"}
              errorMessage={
                "Une erreur est survenue lors de la récupération des actualités"
              }
            >
              {props.news.dataFromPagination?.data?.length > 0 &&
                props.news.dataFromPagination.data
                  ?.filter(
                    (actu) =>
                      actu?.state?.statusLabel !== "Brouillon" ||
                      actu?.createdBy?.id == props.auth.userConnected.id
                  )
                  .sort(
                    (a, b) => new Date(b.modifiedAt) - new Date(a.modifiedAt)
                  )
                  .map((news, index) => (
                    <ActualiteManagerCard
                      titre={news.title ? news.title : "Titre non renseigné"}
                      date={formatDateFrenchLocale(news.publishedAt)}
                      statut={news.state}
                      image={news?.imageUrl || news?.nexusImageUrl || "img1"}
                      nexusUrl={news.nexusImageUrl}
                      key={index}
                      id={news.id}
                      feedback={news.feedback?.feedback}
                      transmitterName={props.news.currentTransmitter[0]}
                    />
                  ))}
            </DataHandler>
          </div>
        </div>
        <TempPagination
          result={newsQuery}
          refItemParent={actualitesContainerRef}
          rows={20}
        />
      </div>
    </>
  );
};

ActualitesManagement.propTypes = {
  auth: PropTypes.object,
  activeIndex: PropTypes.number,
  news: PropTypes.object,
  handleUpdateNews: PropTypes.func,
  handleMenu: PropTypes.func,
  items: PropTypes.object,
  pagination: PropTypes.object,
  handleUpdatePagination: PropTypes.func,
  admin: PropTypes.object,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  news: state.news,
  items: state.items,
  pagination: state.pagination,
  admin: state.admin,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateNews: (value) => {
    dispatch(updateNews(value));
  },
  handleMenu: (value) => {
    dispatch(updateMenu(value));
  },
  handleUpdatePagination: (value) => {
    dispatch(updatePagination(value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActualitesManagement);
